import { BaseItem } from 'domains/account/types'

import agi from '../assets/images/creditCardBrands/agi.png'
import americanExpress from '../assets/images/creditCardBrands/americanExpress.png'
import banescard from '../assets/images/creditCardBrands/banescard.png'
import cabal from '../assets/images/creditCardBrands/cabal.png'
import credsystem from '../assets/images/creditCardBrands/credsystem.png'
import credz from '../assets/images/creditCardBrands/credz.png'
import diners from '../assets/images/creditCardBrands/diners.png'
import elo from '../assets/images/creditCardBrands/elo.png'
import hipercard from '../assets/images/creditCardBrands/hipercard.png'
import jcb from '../assets/images/creditCardBrands/jcb.png'
import mastercard from '../assets/images/creditCardBrands/mastercard.png'
import sorocred from '../assets/images/creditCardBrands/sorocred.png'
import visa from '../assets/images/creditCardBrands/visa.png'

export const creditCardBrands: BaseItem[] = [
  {
    id: 'MASTERCARD',
    name: 'Mastercard',
    imgPath: mastercard,
  },
  { id: 'VISA', name: 'Visa', imgPath: visa },
  {
    id: 'AMEX',
    name: 'American Express',
    imgPath: americanExpress,
  },
  {
    id: 'HIPERCARD',
    name: 'Hipercard',
    imgPath: hipercard,
  },
  {
    id: 'ELO',
    name: 'Elo',
    imgPath: elo,
  },
  {
    id: 'DINERS',
    name: 'Diners Club',
    imgPath: diners,
  },
  {
    id: 'CREDZ',
    name: 'Credz',
    imgPath: credz,
  },
  {
    id: 'SOROCRED',
    name: 'Sorocred',
    imgPath: sorocred,
  },
  {
    id: 'JCB',
    name: 'JCB',
    imgPath: jcb,
  },
  {
    id: 'AGIPLAN',
    name: 'Agiplan',
    imgPath: agi,
  },
  {
    id: 'CABAL',
    name: 'Cabal',
    imgPath: cabal,
  },
  {
    id: 'BANESCARD',
    name: 'Banescard',
    imgPath: banescard,
  },
  {
    id: 'CREDSYSTEM',
    name: 'Credsystem',
    imgPath: credsystem,
  },
]
