import * as Joi from 'joi'

export const loginSchema = Joi.object({
  login: Joi.string().required().messages({
    'string.empty': 'O Usuário precisa ser preenchido',
  }),
  password: Joi.string().required().messages({
    'string.empty': 'A Senha precisa ser preenchida',
  }),
})
