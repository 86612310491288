import { Outlet } from 'react-router-dom'

import { NavBar } from '../Navbar/Navbar'
import styles from './styles.module.scss'
import { Header, Loader } from 'components'
import { useContext } from 'react'
import { UserContext } from 'shared/contexts/user/user'

export const MainContainer = () => {
  const { isLoading } = useContext(UserContext)

  return (
    <>
      <div className={styles.container}>
        <NavBar />

        <div className={styles.header}>
          <Header />
        </div>

        <div className={styles.content}>
          {isLoading ? <Loader isVisible /> : <Outlet />}
        </div>
      </div>
    </>
  )
}
