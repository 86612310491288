import { useMutation } from '@tanstack/react-query'
import { jwtDecode } from 'jwt-decode'

import { AuthService } from 'services/auth'
import { AccessToken, TokenRequest, TokenResponse } from 'services/auth/types'
import { handleSetCookies } from 'utilities/auth'

async function login(request: TokenRequest) {
  return await AuthService.login(request)
}

export const usePostAuth = () => {
  const {
    data: credentials,
    isLoading,
    mutate,
    error,
  } = useMutation((request: TokenRequest) => login(request), {
    retry: false,
    onSuccess: (data: TokenResponse) => {
      const decodedToken = jwtDecode<AccessToken>(data.accessToken)
      handleSetCookies({
        userId: decodedToken.user.applicationId,
        accessToken: data.accessToken,
        refreshToken: data.refreshToken || '',
      })

      sessionStorage.setItem('accessToken', data.accessToken)
      sessionStorage.setItem('refreshToken', data.accessToken)
    },
  })

  return { credentials, isLoading, mutate, error }
}
