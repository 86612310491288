import { forwardRef, ImgHTMLAttributes } from 'react'
import { useQuery } from '@tanstack/react-query'

import Skeleton from 'components/Skeleton'
import ImageDriver from 'services/image'

type ProtectedImageProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'> & {
  imageId?: string
}

const fetchImage = async (imageId: string) => {
  const source = await ImageDriver.getImage(imageId)
  return source
}

export const ProtectedImage = forwardRef<HTMLImageElement, ProtectedImageProps>(
  ({ imageId, alt, ...rest }, ref) => {
    const { data, isLoading } = useQuery({
      queryKey: ['image', imageId],
      queryFn: () => fetchImage(imageId || ''),
      staleTime: 1000 * 60 * 60,
    })

    if (!imageId || isLoading)
      return <Skeleton width={rest.width} height={rest.height} />

    return <img src={data} alt={alt} {...rest} ref={ref} />
  },
)

ProtectedImage.displayName = 'ProtectedImage'
