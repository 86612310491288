import * as Joi from 'joi'
import { validateNumber } from 'domains/account/paymentMethod/validations/directDebit/validations'
import { PaymentMethod } from 'services/account/types'

export type DirectDebitSchema = Pick<
  PaymentMethod,
  'accountNumber' | 'agencyNumber' | 'paymentMethodType' | 'accountId'
> & {
  accountDigit: number
  agencyDigit?: number
  bankNumber: string
}
export const directDebitSchema = Joi.object<DirectDebitSchema>({
  accountNumber: Joi.required().custom(validateNumber).messages({
    'any.required': 'Campo obrigatório',
  }),
  accountDigit: Joi.required().custom(validateNumber).messages({
    'any.required': 'Campo obrigatório',
  }),
  agencyNumber: Joi.required().custom(validateNumber).messages({
    'any.required': 'Campo obrigatório',
  }),
  agencyDigit: Joi.number().optional().allow('', null),
  bankNumber: Joi.string()
    .valid('001', '041', '104', '341', '237', '033')
    .required(),
  paymentMethodType: Joi.string().valid('DIRECT_DEBIT').required(),
  accountId: Joi.string().uuid().required(),
})
