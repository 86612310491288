const PRODUCTION_HOSTNAME = 'portal.ubisafe.io'
const PRODUCTION_BFF_URL = 'https://ubisafe.io/bff/v1/mobile/customer'
const DEVELOPMENT_BFF_URL = 'https://dev.ubisafe.io/bff/v1/mobile/customer'

export function getBaseUrl() {
  if (window.location.hostname === PRODUCTION_HOSTNAME) {
    return PRODUCTION_BFF_URL
  }

  return DEVELOPMENT_BFF_URL
}
