import { ReactNode } from 'react'

import { Icon } from 'components'

export interface NavItem {
  title: string
  path: string
  icon?: ReactNode
  subItems?: NavItem[]
  permission: string[]
}

export const sidebarData: NavItem[] = [
  {
    title: 'Eventos',
    path: '/event/list',
    permission: [],
    icon: <Icon lucideName="circle-alert" width={18} />,
  },
  {
    title: 'Contatos',
    path: '/contacts',
    permission: [],
    icon: <Icon lucideName="contact-round" width={18} />,
  },
  {
    title: 'Zonas e equipamentos',
    path: '/zones',
    permission: [],
    icon: <Icon name="equipments" width={18} />,
  },
]
