import { useQuery } from '@tanstack/react-query'
import { httpClient } from 'config/axios'
import { queryClient } from 'config/reactQuery'
import { parseCookies } from 'nookies'
import { useContext } from 'react'
import { endpoints } from 'services/account/endpoints'
import {
  FindAccount,
  PaymentMethodRequest,
  QueryAccountsResponse,
} from 'services/account/types'
import { UserContext } from 'shared/contexts/user/user'
import buildUrl from 'utilities/buildUrl'

interface AccountServiceDriver {
  savePaymentMethod(request: PaymentMethodRequest): Promise<void>
}

class AccountServiceDriverImpl implements AccountServiceDriver {
  constructor(private readonly client = httpClient) {}

  public async savePaymentMethod(request: PaymentMethodRequest): Promise<void> {
    const { accountId, ...rest } = request
    return await this.client.post(`account/${accountId}/paymentMethod`, rest)
  }

  public async editPaymentMethod(request: PaymentMethodRequest): Promise<void> {
    const { accountId, ...rest } = request
    return await this.client.put(`account/${accountId}/paymentMethod`, rest)
  }
}

export const AccountService = new AccountServiceDriverImpl()

const findAccount = async (id: string): Promise<FindAccount> => {
  const response = await httpClient.get(
    buildUrl({
      route: endpoints.find,
      params: { id },
    }),
  )

  queryClient.invalidateQueries(['accountId'])

  return response.data as FindAccount
}

export function useFindAccount(accountId: string) {
  const { accounts } = useContext(UserContext)

  const validAccounts = accounts || []

  const enabled = validAccounts.length
    ? validAccounts?.includes(accountId) && !!accountId
    : false

  return useQuery({
    queryKey: ['account', accountId],
    queryFn: async () => findAccount(accountId),
    refetchOnWindowFocus: false,
    enabled,
  })
}

export const queryAccounts = async (
  patrimonyId: string,
  code?: string,
  offset = 0,
) => {
  const { userId } = parseCookies()

  const response = await httpClient.get<QueryAccountsResponse>(
    buildUrl({
      route: endpoints.query,
      queryParams: {
        userId,
        recordsPerPage: 15,
        offset: Math.round(offset / 15),
        patrimonyId,
        ...(code && { code }),
      },
    }),
  )

  return response.data
}

export const findUserAccounts = async (userId: string): Promise<string[]> => {
  const response = await httpClient.get(
    buildUrl({
      route: endpoints.userAccounts,
      params: { userId },
    }),
  )

  return response.data as string[]
}

export function useGetUserAccounts(userId: string) {
  return useQuery({
    queryKey: ['userId', userId],
    queryFn: async () => await findUserAccounts(userId),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!userId,
  })
}
