import { AxiosError, AxiosResponse } from 'axios'
import { httpClient } from 'config/axios'
import { RefreshTokenResponse } from 'services/auth/types'

const ignoredPaths = ['/login', '/token']

export class AxiosResponseInterceptor {
  static retry = false
  static onGoingRefreshToken: Promise<void> | null = null

  static onFulfilled(response: AxiosResponse) {
    return response
  }

  private static refreshToken = () => {
    if (this.onGoingRefreshToken) {
      return this.onGoingRefreshToken
    }
    this.onGoingRefreshToken = httpClient
      .post('/refreshToken', {
        refreshToken: sessionStorage.getItem('refreshToken') || '',
      })
      .then((res) => {
        const credentials = res.data as RefreshTokenResponse

        sessionStorage.setItem('accessToken', credentials.accessToken)
        sessionStorage.setItem('refreshToken', credentials.refreshToken)
      })
      .finally(() => {
        this.onGoingRefreshToken = null
      })
    return this.onGoingRefreshToken
  }

  static async onRejected(error: AxiosError) {
    const originalRequest = error.config

    if (ignoredPaths.find((ignored) => ignored === originalRequest.url)) {
      return Promise.reject(error)
    }

    // already tried to refresh the user token
    if (
      error?.response?.status === 401 &&
      originalRequest.url === '/refreshToken'
    ) {
      sessionStorage.removeItem('accessToken')
      sessionStorage.removeItem('refreshToken')
      window.location.pathname = process.env.PUBLIC_URL + '/404'

      return Promise.reject(error)
    }

    // refreshing the user token
    if (error.response?.status === 401 && !this.retry) {
      this.retry = true

      return this.refreshToken().then(() => {
        if (originalRequest.data) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
          originalRequest.data = JSON.parse(originalRequest.data)
        }

        this.retry = false
        return httpClient.request(originalRequest)
      })
    }
    return Promise.reject(error)
  }
}
