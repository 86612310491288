import * as Joi from 'joi'
import { PaymentMethod } from 'services/account/types'

import {
  validateCardholderName,
  validateCardSecurityCode,
  validateCreditCardExpiryDate,
  validateCreditCardNumber,
} from '../../validations/creditCard/validations'

export interface CreditCardSchema
  extends Omit<PaymentMethod, 'cardNumber' | 'cardExpiryDate'> {
  cardNumber: string
  cardExpiryDate: string
}
export const creditCardSchema = Joi.object<CreditCardSchema>({
  accountId: Joi.string().uuid().required(),
  cardBrandName: Joi.string().required(),
  cardExpiryDate: Joi.string()
    .required()
    .custom(validateCreditCardExpiryDate)
    .messages({
      'string.invalid': 'Validade expirada',
      'string.min': 'Data inválida',
      'string.empty': 'Campo obrigatório',
    }),
  cardHolderName: Joi.string()
    .required()
    .custom(validateCardholderName)
    .messages({
      'string.invalid': 'Nome inválido',
      'string.empty': 'Campo obrigatório',
    }),
  cardNumber: Joi.string()
    .required()
    .custom(validateCreditCardNumber)
    .messages({
      'string.invalid': 'Número de cartão inválido',
      'string.min': 'Número de cartão inválido ou não aceito',
      'string.empty': 'Campo obrigatório',
    }),
  cardSecurityCode: Joi.string()
    .custom(validateCardSecurityCode)
    .required()
    .messages({
      'string.min': 'Mínimo de 3 dígitos',
      'string.empty': 'Campo obrigatório',
    }),
  paymentMethodType: Joi.string().valid('CREDIT_CARD').required(),
})
