import { ComboboxItem } from '../Combobox'

export interface Item {
  id: string
  imgPath?: string
}

export function getItemValue<T>(
  item: string | ComboboxItem<T>,
): string | T[keyof T] | undefined {
  if (typeof item === 'string') {
    return item
  }

  if (!item.value) return

  if (typeof item.value === 'string') {
    return item.value
  }

  return item.value[item.label]
}
