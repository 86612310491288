import { Accordion, Icon, Loader, Tabs } from 'components'

import styles from './styles.module.scss'
import joinClassNames from 'utilities/joinClassNames'
import { useGetContacts } from 'services/contacts/service'
import { maskPhoneNumber } from 'utilities/mask/phone'
import { EmptyState } from 'domains/contact/screens/Contacts/components'

const Contacts = () => {
  const accountId = localStorage.getItem('accountId') || ''

  const { data, isFetching } = useGetContacts(accountId)

  const handleRender = () => {
    if (isFetching) return 'loading'

    return 'view'
  }

  return (
    <div className={styles.container}>
      <h2>Contatos</h2>

      <Tabs.Root>
        <Tabs.List>
          <Tabs.Tab>Plano de ação</Tabs.Tab>
          <Tabs.Tab>Usuários da central</Tabs.Tab>
        </Tabs.List>

        {
          {
            view: (
              <Tabs.Panels>
                <Tabs.Panel>
                  <div className={styles.panelContainer}>
                    {data?.partitionsWithContacts?.length ? (
                      data?.partitionsWithContacts
                        ?.sort((a, b) => Number(a.code) - Number(b.code))
                        .map((partition) => (
                          <div
                            className={styles.accordionContainer}
                            key={partition.id}
                          >
                            <Accordion.Root>
                              <Accordion.Item
                                initialState={'open'}
                                className={styles.accordionItem}
                              >
                                {({ isOpen }) => (
                                  <>
                                    <Accordion.Header className={styles.header}>
                                      <Accordion.Trigger>
                                        <Accordion.Icon />
                                        {`Partição ${partition.code} - ${partition.name}`}
                                      </Accordion.Trigger>
                                    </Accordion.Header>

                                    <Accordion.Content
                                      className={joinClassNames(
                                        styles.content,
                                        isOpen && styles.open,
                                      )}
                                    >
                                      <ul>
                                        {partition?.actionPlan
                                          ?.sort(
                                            (a, b) =>
                                              a.priorityOrder - b.priorityOrder,
                                          )
                                          .map((contact, index) => (
                                            <li key={index}>
                                              <span>{index + 1}º</span>
                                              <div>
                                                <span>
                                                  {contact.name}

                                                  {contact.type === 'ADMIN' && (
                                                    <Icon
                                                      name="shield"
                                                      width={12}
                                                    />
                                                  )}
                                                </span>
                                                <span>
                                                  {maskPhoneNumber(
                                                    `${contact.phone?.provinceCode},${contact.phone?.number}`,
                                                  )}
                                                </span>
                                              </div>
                                            </li>
                                          ))}
                                      </ul>
                                    </Accordion.Content>
                                  </>
                                )}
                              </Accordion.Item>
                            </Accordion.Root>
                          </div>
                        ))
                    ) : (
                      <div className={styles.wrapper}>
                        <EmptyState message="Não há plano de ação registrado para esta conta." />
                      </div>
                    )}
                  </div>
                </Tabs.Panel>
                <Tabs.Panel>
                  <div className={styles.panelContainer}>
                    <ul>
                      {data?.centralUsers?.length ? (
                        data.centralUsers
                          ?.sort((a, b) => Number(a.code) - Number(b.code))
                          .map((user, index) => (
                            <li key={index} className={styles.userCentral}>
                              <div>
                                <span>
                                  {user.name}
                                  <Icon name="shield" width={12} />
                                </span>
                                <span>
                                  <div>
                                    {`Posição ${user.code.padStart(2, '0')}`}
                                  </div>

                                  {!!user.partitions?.length && (
                                    <small>•</small>
                                  )}

                                  <div>
                                    {user.partitions
                                      ?.sort(
                                        (a, b) =>
                                          Number(a.code) - Number(b.code),
                                      )
                                      .map(
                                        (partition) =>
                                          `Partição ${partition.code}`,
                                      )
                                      .join(', ')}
                                  </div>
                                </span>
                              </div>
                            </li>
                          ))
                      ) : (
                        <div className={styles.wrapper}>
                          <EmptyState message="A central desta conta não possui usuários cadastrados." />
                        </div>
                      )}
                    </ul>
                  </div>
                </Tabs.Panel>
              </Tabs.Panels>
            ),
            loading: (
              <div className={styles.loader}>
                <Loader isVisible />
              </div>
            ),
          }[handleRender()]
        }
      </Tabs.Root>
    </div>
  )
}

export default Contacts
