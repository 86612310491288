import { useQuery } from '@tanstack/react-query'
import { AxiosInstance } from 'axios'
import { httpClient } from 'config/axios'
import { endpoints } from 'services/patrimony/endpoints'
import {
  FindAllPatrimoniesResponse,
  UserPatrimoniesResponse,
} from 'services/patrimony/types'
import buildUrl from 'utilities/buildUrl'

interface PatrimonyServiceDriver {
  getUserPatrimonies(userId: string): Promise<UserPatrimoniesResponse>
}

class PatrimonyServiceDriverImpl implements PatrimonyServiceDriver {
  public constructor(private readonly client: AxiosInstance = httpClient) {}

  public async getUserPatrimonies(
    userId: string,
  ): Promise<UserPatrimoniesResponse> {
    const response = await this.client.get<UserPatrimoniesResponse>(
      `/user/${userId}/patrimony`,
    )

    return response.data
  }
}

export const PatrimonyService = new PatrimonyServiceDriverImpl()

export const findAllPatrimonies = async (
  userId: string,
): Promise<FindAllPatrimoniesResponse> => {
  const response = await httpClient.get(
    buildUrl({
      route: endpoints.findAll,
      params: { userId },
    }),
  )

  return response.data as FindAllPatrimoniesResponse
}

export function useFindAllPatrimonies(userId: string) {
  return useQuery({
    queryKey: ['userId', userId],
    queryFn: async () => await findAllPatrimonies(userId),
    refetchOnWindowFocus: false,
  })
}
