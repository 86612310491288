import { BrowserRouter } from 'react-router-dom'
import { UbiRoutes } from 'routes/Routes'
import { queryClient } from 'config/reactQuery'
import { QueryClientProvider } from '@tanstack/react-query'
import { UserProvider } from 'shared/contexts/user/user'

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <UserProvider>
          <UbiRoutes />
        </UserProvider>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App
