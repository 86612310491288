export function maskPhoneNumber(phone: string): string {
  const digits = phone.replace(/\D/g, '')

  const regex =
    digits.length === 10
      ? /(\d{2})(\d{4})(\d{4})/
      : digits.length === 11
        ? /(\d{2})(\d{5})(\d{4})/
        : null

  if (!regex) return '-'

  return digits.replace(regex, '($1) $2-$3')
}
