import { useGetZoneWithEquipments } from 'services/zone'
import { Accordion, Icon, Loader } from 'components'
import styles from './styles.module.scss'
import { EmptyState } from 'domains/zone/screens/Zones/components'
import joinClassNames from 'utilities/joinClassNames'

const Zones = () => {
  const { data: zones, isFetching } = useGetZoneWithEquipments(
    localStorage.getItem('accountId') || '',
  )

  const getDeviceIconName = {
    'Sensor de abertura': 'door',
    Sirene: 'siren',
    Controle: 'control',
    Fotosensor: 'cam',
  }

  const handleRender = () => {
    if (isFetching) return 'loading'

    if (
      zones?.partitions?.every((partition) => !partition.devices.length) &&
      !isFetching
    )
      return 'empty'

    return 'view'
  }

  return (
    <>
      <div className={styles.header}>
        <h2>Zonas e Equipamentos</h2>
      </div>
      <div className={styles.container}>
        <section>
          {
            {
              view: (
                <>
                  <div className={styles.panelContainer}>
                    {zones?.partitions?.length ? (
                      zones?.partitions
                        ?.sort((a, b) => Number(a.code) - Number(b.code))
                        .map((partition) => (
                          <div
                            className={styles.accordionContainer}
                            key={partition.id}
                          >
                            {partition?.devices?.length ? (
                              <Accordion.Root>
                                <Accordion.Item
                                  initialState={'open'}
                                  className={styles.accordionItem}
                                >
                                  {({ isOpen }) => (
                                    <>
                                      <Accordion.Header
                                        className={styles.header}
                                      >
                                        <Accordion.Trigger>
                                          <Accordion.Icon />
                                          {`Partição ${partition.code} - ${partition.name}`}
                                        </Accordion.Trigger>
                                      </Accordion.Header>

                                      <Accordion.Content
                                        className={joinClassNames(
                                          styles.content,
                                          isOpen && styles.open,
                                        )}
                                      >
                                        <ul>
                                          {partition?.devices?.map(
                                            (device, index) => (
                                              <li key={index}>
                                                <div>
                                                  <span>{index + 1}</span>
                                                  <div>
                                                    <span>{device.name}</span>
                                                    <span>
                                                      {device.typeName}
                                                    </span>
                                                  </div>
                                                </div>
                                                <div>
                                                  <Icon
                                                    name={
                                                      getDeviceIconName[
                                                        device.typeName
                                                      ] ?? 'siren'
                                                    }
                                                    width={19}
                                                    height={18}
                                                  />
                                                </div>
                                              </li>
                                            ),
                                          )}
                                        </ul>
                                      </Accordion.Content>
                                    </>
                                  )}
                                </Accordion.Item>
                              </Accordion.Root>
                            ) : null}
                          </div>
                        ))
                    ) : (
                      <div className={styles.wrapper}>
                        <EmptyState message="Não há plano de ação registrado para esta conta." />
                      </div>
                    )}
                  </div>
                </>
              ),
              loading: (
                <div className={styles.wrapper}>
                  <Loader isVisible />
                </div>
              ),
              empty: (
                <div className={styles.wrapper}>
                  <EmptyState message="Não há equipamentos instalados para esta conta." />
                </div>
              ),
            }[handleRender()]
          }
        </section>
      </div>
    </>
  )
}

export default Zones
