import { PaymentMethod } from 'domains/account'
import { Login } from 'domains/auth'
import AppAuthentication from 'domains/auth/screens/AppAuthentication'
import { Contacts } from 'domains/contact/screens'
import { Events } from 'domains/event/screens'
import { Zones } from 'domains/zone/screens'
import { parseCookies } from 'nookies'
import { useEffect } from 'react'

import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { MainContainer } from 'routes/components/MainContainer/MainContainer'

export const UbiRoutes: React.FC = () => {
  interface StartedSessionContainerProps {
    children: JSX.Element
  }

  const StartedSessionContainer = ({
    children,
  }: StartedSessionContainerProps) => {
    const location = useLocation()
    const navigate = useNavigate()

    const { userId } = parseCookies()

    useEffect(() => {
      if (!userId && location.pathname !== '/login') {
        navigate('/login', { state: { from: location } })
      }
    }, [])

    return children
  }

  return (
    <Routes location={location}>
      <Route path="/:clientId/:userId" element={<AppAuthentication />} />
      <Route path="/login" element={<Login />} />
      <Route path="/payment-method/:userId" element={<PaymentMethod />} />
      <Route
        path="/"
        element={
          <StartedSessionContainer>
            <MainContainer />
          </StartedSessionContainer>
        }
      >
        <Route path="/event/list" element={<Events />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/zones" element={<Zones />} />
      </Route>
    </Routes>
  )
}
