import React, { useEffect, useState } from 'react'

import { CSSTransition } from 'react-transition-group'

import { ReactComponent as ErrorIcon } from './assets/svg/error.svg'
import { ReactComponent as SuccessIcon } from './assets/svg/success.svg'

import './Toast.styles.scss'

export interface ToastProps {
  isVisible: boolean
  error?: boolean
  autohideTimeout?: number
  message: string
}
export function Toast(props: ToastProps) {
  const { message, isVisible, error, autohideTimeout = 0 } = props

  const [showToast, setShowToast] = useState(false)

  useEffect(() => {
    setShowToast(isVisible)
  }, [isVisible])

  useEffect(() => {
    if (autohideTimeout) {
      const timer = setTimeout(() => {
        setShowToast(false)
      }, autohideTimeout)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [autohideTimeout, isVisible])

  return (
    <React.Fragment>
      <CSSTransition
        in={showToast}
        timeout={{
          enter: 400,
          exit: 100,
        }}
        classNames="slide-in"
        unmountOnExit
      >
        <div
          role={error ? 'alert' : 'informative'}
          className={['toast', error && '--error'].join(' ')}
        >
          {error ? (
            <ErrorIcon className="toast-icon" />
          ) : (
            <SuccessIcon className="toast-icon" />
          )}
          <p className="toast-text">{message}</p>
        </div>
      </CSSTransition>
    </React.Fragment>
  )
}

export default Toast
