export function getCreditCardNumberMaxLengthWithMask(cardBrandName: string) {
  switch (cardBrandName) {
    case 'DINERS':
      return 16
    case 'AMEX':
      return 17
    default:
      return 19
  }
}
