import dayjs from 'dayjs'

const CURRENT_MILLENIUM = 2000

export const formatTimestampFromExpiryDate = (expDate: string) => {
  const unmaskedValue = expDate.replace(/\D/g, '')

  const expiryDateMonth = Number(unmaskedValue.slice(0, 2))
  const expiryDateYear = Number(unmaskedValue.slice(2, 4)) + CURRENT_MILLENIUM

  const date = dayjs()
    .set('year', expiryDateYear)
    .set('month', expiryDateMonth - 1)
    .set('ms', 0)
    .set('s', 0)
    .set('m', 0)
    .set('h', 0)
    .set('date', 1)

  const formattedTimestamp = dayjs(date).valueOf()

  return formattedTimestamp
}
