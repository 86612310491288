import {
  ComponentProps,
  createContext,
  MouseEvent,
  ReactElement,
  ReactNode,
  useContext,
  useReducer,
} from 'react'

import { IconProps } from 'components/Icon'

import { Icon as IconComponent } from 'components'

import styles from './styles.module.scss'

interface AccordionContextProps {
  isOpen: boolean
  toggle: () => void
}

export const AccordionContext = createContext<AccordionContextProps>({
  isOpen: false,
  toggle: () => null,
})

const Root = (props: ComponentProps<'div'>) => <div {...props} />

type ChildrenProps = {
  isOpen: boolean
}

type ItemProps = Omit<ComponentProps<'div'>, 'children'> & {
  initialState?: 'open' | 'closed'
  children?: ReactNode | ((props: ChildrenProps) => ReactNode)
}

const Item = ({
  className,
  children,
  initialState = 'closed',
  ...props
}: ItemProps) => {
  const [isOpen, toggle] = useReducer((old) => !old, initialState === 'open')

  return (
    <AccordionContext.Provider value={{ isOpen, toggle }}>
      <div
        {...props}
        className={[styles.item, isOpen && styles.open, className].join(' ')}
      >
        {typeof children === 'function' ? children({ isOpen }) : children}
      </div>
    </AccordionContext.Provider>
  )
}

const Content = ({ className, ...props }: ComponentProps<'div'>) => {
  const { isOpen } = useContext(AccordionContext)

  return (
    <div
      {...props}
      className={[
        `${styles.content} ${isOpen ? styles.open : styles.closed}`,
        className,
      ].join(' ')}
    />
  )
}

const Header = ({ className, ...props }: ComponentProps<'div'>) => {
  return <div {...props} className={[styles.header, className].join(' ')} />
}

const Trigger = ({
  className,
  onClick,
  children,
  ...props
}: ComponentProps<'button'> & { children?: ReactNode }) => {
  const { toggle } = useContext(AccordionContext)

  const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    toggle()
    onClick?.(event)
  }

  return (
    <button
      className={[styles.trigger, className].join(' ')}
      type="button"
      {...props}
      onClick={handleOnClick}
    >
      {children}
    </button>
  )
}

const Icon = ({
  className,
  ...props
}: Omit<IconProps, 'name'>): ReactElement => {
  const { isOpen } = useContext(AccordionContext)

  return (
    <IconComponent
      name="chevron-down"
      {...props}
      className={[styles.arrow, isOpen && styles.upsideArrow, className].join(
        ' ',
      )}
    />
  )
}

const Accordion = {
  Root,
  Item,
  Content,
  Header,
  Trigger,
  Icon,
}

export default Accordion
