import { ButtonV2, Loader, Toast } from 'components'

import { useNavigate } from 'react-router-dom'

import {
  AccessToken,
  LoginRequest,
  usePostLogon,
  usePostResetPassword,
} from 'services/auth'

import { FormItem, FormLabel, TextField } from 'components/Form'
import { useForm } from 'react-hook-form'

import { joiResolver } from '@hookform/resolvers/joi'
import { loginSchema } from 'domains/auth/screens/Login/schemas'
import { jwtDecode } from 'jwt-decode'
import { handleSetCookies } from 'services/auth/utilities/auth'

import styles from './Login.module.scss'
import { useContext, useEffect, useState } from 'react'
import { UserContext } from 'shared/contexts/user/user'
import Modal from 'components/ModalV2'
import { useToggle } from 'shared/hooks'

export const Login: React.FunctionComponent = () => {
  const navigate = useNavigate()

  const [welcomeImage, setWelcomeImage] = useState({ src: '', isLoaded: false })

  const [modals, setModals] = useState({
    forgotPassword: false,
    sendPassword: false,
  })

  const { setUser, fetchUserAccounts } = useContext(UserContext)

  const { mutate: mutateLogon, isLoading: isLoadingLogon } = usePostLogon()
  const { mutate: mutateResetPassWord } = usePostResetPassword()

  const passwordToast = useToggle()

  const { register, handleSubmit, watch } = useForm<LoginRequest>({
    mode: 'onBlur',

    resolver: joiResolver(loginSchema),
  })

  const handleResetPassword = () => {
    mutateResetPassWord(
      { email: watch('login') },
      {
        onSuccess: () =>
          setModals({ sendPassword: true, forgotPassword: false }),
      },
    )
  }

  const handleOnSubmit = (data: LoginRequest) => {
    mutateLogon(data, {
      onSuccess: (data) => {
        const decodedToken = jwtDecode<AccessToken>(data.accessToken)

        handleSetCookies({
          userId: decodedToken.user.applicationId,
          email: watch('login'),
          profileImageId: data.profileImageId || '',
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
        })

        localStorage.setItem('accountId', data.defaultAccountId || '')

        setUser({
          profileImageId: data.profileImageId || '',
          defaultAccountId: data.defaultAccountId || '',
          email: watch('login') || '',
          name: data.name,
        })

        navigate(`/event/list`)

        fetchUserAccounts()
      },
      onError: (error) => {
        if (error?.response?.data?.error_description === 'Bad credentials') {
          passwordToast.show()
        }
      },
    })
  }

  const loadImage = async () => {
    const randomIndex = Math.floor(Math.random() * 5) + 1

    const imageModule = await import(
      `../../assets/images/welcome-${randomIndex}.png`
    )
    setWelcomeImage({ src: imageModule.default, isLoaded: false })
  }

  const handleRender = () => {
    if (!welcomeImage) return 'loading'

    return 'view'
  }

  useEffect(() => {
    loadImage()
  }, [])

  return (
    <div className={styles.main}>
      {
        {
          view: (
            <div className={styles.container}>
              {isLoadingLogon ||
                (!welcomeImage.isLoaded && (
                  <div className={styles.loader}>
                    <Loader isVisible />
                  </div>
                ))}

              <Modal.Root
                isOpen={modals.forgotPassword}
                onClose={() =>
                  setModals((prev) => ({ ...prev, forgotPassword: false }))
                }
              >
                <Modal.Content className={styles.modalContent}>
                  <Modal.Title>Recuperar senha</Modal.Title>
                  <div>
                    <p className={styles.resetModalText}>
                      Informe o e-mail cadastrado na sua conta e lhe enviaremos
                      um link com as instruções para recuperar seu acesso ao
                      portal de monitoramento.
                    </p>

                    <FormItem>
                      <FormLabel>E-mail</FormLabel>
                      <TextField {...register('login')} />
                    </FormItem>
                    <Modal.Footer>
                      <Modal.Close asChild>
                        <ButtonV2 appearance="tertiary">Cancelar</ButtonV2>
                      </Modal.Close>
                      <ButtonV2 onClick={handleResetPassword} type="button">
                        Enviar link
                      </ButtonV2>
                    </Modal.Footer>
                  </div>
                </Modal.Content>
              </Modal.Root>

              <Modal.Root
                isOpen={modals.sendPassword}
                onClose={() =>
                  setModals((prev) => ({ ...prev, sendPassword: false }))
                }
              >
                <Modal.Content className={styles.modalContent}>
                  <Modal.Title>Senha solicitada com sucesso</Modal.Title>

                  <p>
                    Verifique o e-mail informado e siga as instruções para
                    recuperar o seu acesso ao Ubiportal
                  </p>

                  <Modal.Footer>
                    <Modal.Close asChild>
                      <ButtonV2 appearance="tertiary">Cancelar</ButtonV2>
                    </Modal.Close>
                  </Modal.Footer>
                </Modal.Content>
              </Modal.Root>

              <img
                src={welcomeImage.src}
                loading="eager"
                onLoad={() =>
                  setWelcomeImage((prev) => ({ ...prev, isLoaded: true }))
                }
              />

              {welcomeImage.isLoaded && (
                <div className={styles.formContainer}>
                  <form onSubmit={handleSubmit(handleOnSubmit)}>
                    <div className={styles.wrapper}>
                      <div>
                        <h1>
                          Boas-vindas ao <br />
                          Portal do Cliente <br /> Orsegups
                        </h1>
                        <h2>
                          Conte conosco para proteger o que mais importa: você,
                          sua família e seu patrimônio.
                        </h2>
                      </div>

                      <div className={styles.fields}>
                        {passwordToast.isVisible && (
                          <Toast
                            message="Usuário ou senha inválidos. Tente novamente ou clique em ‘Esqueci minha senha’."
                            error
                            autohideTimeout={0}
                            isVisible
                          />
                        )}

                        <FormItem>
                          <FormLabel>E-mail</FormLabel>
                          <TextField
                            {...register('login')}
                            spellCheck="false"
                          />
                        </FormItem>

                        <FormItem>
                          <FormLabel>Senha</FormLabel>
                          <TextField
                            type="password"
                            {...register('password')}
                          />
                        </FormItem>
                      </div>

                      <div className={styles.footer}>
                        <ButtonV2
                          style={{ width: 328 }}
                          type="submit"
                          appearance="primary"
                        >
                          Entrar
                        </ButtonV2>

                        <ButtonV2
                          onClick={() =>
                            setModals((prev) => ({
                              ...prev,
                              forgotPassword: true,
                            }))
                          }
                          type="button"
                          appearance="tertiary"
                        >
                          Esqueci minha senha
                        </ButtonV2>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          ),
          loading: <Loader isVisible />,
        }[handleRender()]
      }
    </div>
  )
}
