import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query'
import { Loader } from 'components'
import { parseCookies } from 'nookies'
import { createContext, Dispatch, ReactNode, useState } from 'react'

import { useGetUserAccounts } from 'services/account'

type UserData = {
  name: string
  email: string
  profileImageId: string
  defaultAccountId: string
}

export interface UserContextData {
  user: UserData
  setUser: Dispatch<React.SetStateAction<UserData>>
  accounts?: string[]
  isLoading: boolean
  fetchUserAccounts: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<string[] | undefined>>
}

export const UserContext = createContext<UserContextData>({} as UserContextData)

interface UserProviderProps {
  children: ReactNode
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const { userId } = parseCookies()

  const { data, isLoading, isFetching, refetch } = useGetUserAccounts(userId)

  const [user, setUser] = useState({
    name: '',
    email: '',
    profileImageId: '',
    defaultAccountId: '',
  })

  const value: UserContextData = {
    user,
    setUser,
    accounts: data,
    isLoading: isFetching,
    fetchUserAccounts: refetch,
  }

  return (
    <UserContext.Provider value={value}>
      {isLoading && !!userId ? (
        <div>
          <Loader isVisible />
        </div>
      ) : (
        children
      )}
    </UserContext.Provider>
  )
}
