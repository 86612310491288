import { Icon } from 'components'
import { ReactNode } from 'react'
import { DeviceType } from 'services/event'

export enum SensorTypeEnum {
  MAIN = 1,
  TAMPER = 2,
  ANTI_SABOTAGE = 3,
  ACCELEROMETER = 4,
  OPENING = 5,
}

export const getDeviceSensorName = (
  deviceType?: DeviceType,
  sensorType?: number,
): string => {
  if (deviceType === DeviceType.WirelessSiren) {
    if (sensorType === SensorTypeEnum.TAMPER) return 'tamper'
  }

  if (deviceType === DeviceType.Central) {
    if (sensorType === SensorTypeEnum.TAMPER) return 'tamper'
  }

  if (deviceType === DeviceType.PassiveInfraPhotoRedSensor) {
    if (sensorType === SensorTypeEnum.MAIN) return 'detecção'
    if (sensorType === SensorTypeEnum.TAMPER) return 'tamper'
    if (sensorType === SensorTypeEnum.ANTI_SABOTAGE) return 'sabotagem'
  }

  if (deviceType === DeviceType.ReedSwitch) {
    if (sensorType === SensorTypeEnum.MAIN) return 'magnético'
    if (sensorType === SensorTypeEnum.TAMPER) return 'tamper'
    if (sensorType === SensorTypeEnum.ACCELEROMETER) return 'shock'
  }

  return ''
}

export const getNormalizedDeviceTypeCode = (
  deviceTypeCode?: DeviceType,
): DeviceType | undefined => {
  if (!deviceTypeCode) return undefined
  if (deviceTypeCode === '99') return DeviceType.Central
  return deviceTypeCode as unknown as DeviceType
}

interface DeviceInfo {
  image: ReactNode
  type: string
}

const devices: Record<DeviceType, DeviceInfo> = {
  '0': {
    image: <Icon name="equipments" width={110} height={80} />,
    type: 'Controle remoto',
  },
  '1': {
    image: <Icon name="equipments" width={110} height={80} />,
    type: 'IVP',
  },
  '2': {
    image: <Icon name="equipments" width={110} height={80} />,
    type: 'Fotosensor',
  },
  '4': {
    image: <Icon name="equipments" width={110} height={80} />,
    type: 'Sensor de abertura',
  },
  '5': {
    image: <Icon name="equipments" width={110} height={80} />,
    type: 'Sirene',
  },
  '99': {
    image: <Icon name="equipments" width={110} height={80} />,
    type: 'Módulo',
  },
}

export const getDeviceInfo = (deviceType: DeviceType): DeviceInfo | undefined =>
  devices[deviceType === ('6' as DeviceType) ? '99' : deviceType]
