import { Icon } from 'components'
import styles from './styles.module.scss'

const EmptyState = () => (
  <div className={styles.emptyState}>
    <Icon name="empty-state" width={112} height={112} />
    <h3>Não há eventos registrados para esta conta.</h3>
  </div>
)

export default EmptyState
