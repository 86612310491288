import { Icon } from 'components'
import styles from './styles.module.scss'

const EmptyState = ({ message = '' }) => (
  <div className={styles.emptyState}>
    <Icon name="empty-contact" width={112} height={112} />
    <h3>{message}</h3>
  </div>
)

export default EmptyState
