import { createMasker, MaskArgs } from 'utilities/mask/mask'

const expiryDateMaskerArgs: MaskArgs[] = [
  { mask: '', regex: /\D/g },
  {
    mask: '$1/$2',
    regex: /^(\d{2})(\d)/g,
  },
]

export const expiryDateMasker = createMasker(expiryDateMaskerArgs)

function getCreditCardNumberMaskerArgs(cardBrandName: string) {
  switch (cardBrandName) {
    case 'AMEX':
      return [
        { mask: '', regex: /\D/g },
        {
          mask: '$1 $2',
          regex: /^(\d{4})(\d)/g,
        },
        {
          mask: '$1 $2 $3',
          regex: /^(\d{4}) (\d{6})(\d)/g,
        },
        {
          mask: '$1 $2 $3 $4',
          regex: /^(\d{4}) (\d{6}) (\d{5})(\d)/g,
        },
      ]
    case 'DINERS':
      return [
        { mask: '', regex: /\D/g },
        {
          mask: '$1 $2',
          regex: /^(\d{4})(\d)/g,
        },
        {
          mask: '$1 $2 $3',
          regex: /^(\d{4}) (\d{6})(\d)/g,
        },
        {
          mask: '$1 $2 $3 $4',
          regex: /^(\d{4}) (\d{6}) (\d{4})(\d)/g,
        },
      ]
    default:
      return [
        { mask: '', regex: /\D/g },
        {
          mask: '$1 $2',
          regex: /^(\d{4})(\d)/g,
        },
        {
          mask: '$1 $2 $3',
          regex: /^(\d{4}) (\d{4})(\d)/g,
        },
        {
          mask: '$1 $2 $3 $4',
          regex: /^(\d{4}) (\d{4}) (\d{4})(\d)/g,
        },
      ]
  }
}

export const creditCardNumberMasker = (
  value: string,
  cardBrandName = 'default',
) => {
  const maskerArgs = getCreditCardNumberMaskerArgs(cardBrandName)
  const masker = createMasker(maskerArgs)

  return masker(value)
}
