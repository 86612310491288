import React, { useEffect } from 'react'
import { Button, Combobox, ComboboxItem, Input, Toast } from 'components'
import { banks } from '../../data/banks'
import { BaseItem } from 'domains/account/types'

import styles from './DirectDebitForm.module.scss'
import { useForm } from 'react-hook-form'
import {
  directDebitSchema,
  DirectDebitSchema,
} from 'domains/account/paymentMethod/schemas/directDebit/directDebit'
import { PaymentMethodRequest, PaymentMethodType } from 'services/account/types'
import { joiResolver } from '@hookform/resolvers/joi'
import { usePostPaymentMethod } from 'shared/hooks/services/account'
import { useToastProps } from 'shared/hooks/components/toast'
import { usePutPaymentMethod } from 'shared/hooks/services/account/usePutPaymentMethod'
import { MutateOptions } from '@tanstack/react-query'
interface DirectDebitForm {
  accountId: string
  onSubmitSuccess: () => void
  paymentMethodType?: PaymentMethodType
}

export function DirectDebitForm({
  accountId,
  onSubmitSuccess,
  paymentMethodType,
}: DirectDebitForm): JSX.Element {
  const comboboxBanks: ComboboxItem<BaseItem>[] = banks.map((brand) => ({
    label: 'name',
    value: {
      id: brand.id,
      name: brand.name,
    },
  }))

  const {
    trigger,
    setValue,
    register,
    handleSubmit,
    formState: { isDirty, isValid, errors },
  } = useForm<DirectDebitSchema>({
    mode: 'onChange',
    resolver: joiResolver(directDebitSchema),
    defaultValues: {
      paymentMethodType: 'DIRECT_DEBIT',
      accountId,
      agencyDigit: undefined,
    },
  })

  const saveMutation = usePostPaymentMethod()
  const editMutation = usePutPaymentMethod()

  const saveToastProps = useToastProps({
    status: saveMutation.status,
    successMessage: 'Forma de pagamento cadastrada com sucesso.',
    errorMessage:
      'Não foi possível cadastrar a forma de pagamento. Tente novamente.',
  })

  const editToastProps = useToastProps({
    status: editMutation.status,
    successMessage: 'Forma de pagamento editada com sucesso.',
    errorMessage:
      'Não foi possível editar a forma de pagamento. Tente novamente.',
  })

  const onSubmit = (form: DirectDebitSchema) => {
    const request: PaymentMethodRequest = {
      accountNumber: +(String(form.accountNumber) + String(form.accountDigit)),
      agencyNumber: form.agencyNumber,
      ...(form.agencyDigit && {
        agencyNumber: +(String(form.agencyNumber) + String(form.agencyDigit)),
      }),
      bankNumber: +form.bankNumber,
      paymentMethodType: form.paymentMethodType,
      accountId: form.accountId,
    }

    const options: MutateOptions<void, unknown, PaymentMethodRequest, unknown> =
      {
        onSuccess: () => {
          onSubmitSuccess()
        },
      }

    if (paymentMethodType) {
      editMutation.mutate(request, options)
    } else {
      saveMutation.mutate(request, options)
    }
  }

  useEffect(() => {
    register('bankNumber')
  }, [register])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.container}>
      <Toast {...saveToastProps} />
      <Toast {...editToastProps} />
      <Combobox
        label="Banco"
        items={comboboxBanks}
        getSelected={(item) => {
          if (item?.value) {
            setValue('bankNumber', item.value.id)
            trigger('bankNumber')
          }
        }}
      />
      <div>
        <div className={styles.inputWrapper}>
          <Input
            label="Agência"
            type="number"
            inputMode="numeric"
            maxLength={5}
            {...register('agencyNumber')}
            errorMessage={errors.agencyNumber?.message}
          />
          <Input
            label="Dígito (Opcional)"
            type="number"
            inputMode="numeric"
            maxLength={1}
            {...register('agencyDigit')}
            errorMessage={errors.agencyDigit?.message}
          />
          <Input
            label="Conta"
            type="number"
            inputMode="numeric"
            maxLength={20}
            {...register('accountNumber')}
            errorMessage={errors.accountNumber?.message}
          />
          <Input
            label="Dígito"
            type="number"
            inputMode="numeric"
            maxLength={1}
            {...register('accountDigit')}
            errorMessage={errors.accountDigit?.message}
          />
        </div>
      </div>
      <Button
        title="Salvar"
        htmlType="submit"
        disabled={!isDirty || !isValid}
      />
    </form>
  )
}
