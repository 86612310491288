export type RefreshTokenResponse = {
  accessToken: string
  refreshToken: string
}

export enum GrantTypes {
  'Login' = 'password',
  'RefreshToken' = 'refresh_token',
  'Validation' = 'validate',
  'UbisafeCode' = 'ubisafe_code',
}

export type TokenRequest = {
  grantType: GrantTypes
  realm?: string
  scope?: string
  refreshToken?: string
  clientId?: string
}

export type TokenResponse = {
  accessToken: string
  tokenType?: string
  expiresIn?: number
  refreshToken?: string
  code?: string
}

export type LoginRequest = {
  login: string
  password: string
  mobileDeviceToken?: string
  mobileDeviceType?: string
}

export type LoginResponse = {
  accessToken: string
  token_type: string
  expires_in: number
  refreshToken: string
}

export type ResetPasswordRequest = {
  email: string
}

export type AccessToken = {
  realm: string
  scopes: string
  exp: number
  iat: number
  user: {
    applicationId: string
    username: string
  }
  jti: string
}

export type UserLoggedIn = {
  id: string
  name: string
  profileImageId: string | null
  defaultAccountId: string | null
  accessToken: string
  refreshToken: string
}
