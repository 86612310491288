import { useMutation } from '@tanstack/react-query'
import { AccountService } from 'services/account'
import { PaymentMethodRequest } from 'services/account/types'

async function savePaymentMethod(request: PaymentMethodRequest) {
  return await AccountService.savePaymentMethod(request)
}

export const usePostPaymentMethod = () => {
  const { isLoading, mutate, error, status } = useMutation(
    (request: PaymentMethodRequest) => savePaymentMethod(request),
    {
      retry: false,
    },
  )

  return { isLoading, mutate, error, status }
}
