import { useQuery } from '@tanstack/react-query'
import { USER_PATRIMONIES } from 'config/reactQuery/keys/patrimony'

import { PatrimonyService } from 'services/patrimony'

async function fetchUserPatrimonies(userId: string) {
  const response = await PatrimonyService.getUserPatrimonies(userId)
  return response.data
}

export function useGetUserPatrimonies(userId: string) {
  const {
    data: patrimonies = [],
    isLoading,
    isFetching,
    refetch,
  } = useQuery([USER_PATRIMONIES, userId], () => fetchUserPatrimonies(userId), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(userId),
  })
  return { patrimonies, isLoading, isFetching, refetch }
}
