export const maskedDateTimeHour = (datetime?: number): string => {
  let formattedDate = '00/00/0000 às 00:00'
  if (datetime) {
    const date = new Date(datetime)

    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')

    formattedDate = `${formatDecimal(day)}/${formatDecimal(month)}/${formatDecimal(year)} às ${hours}:${minutes}:${seconds}`
  }

  return formattedDate
}

export const formatDecimal = (value: number): string => {
  return value.toString().padStart(2, '0')
}
