import { FC, SVGProps, Suspense, forwardRef, lazy, useMemo } from 'react'
import {} from 'lucide-react'
import dynamicIconImports from 'lucide-react/dynamicIconImports'

import styles from './styles.module.scss'
import { IconName } from './icon-names'
import joinClassNames from 'utilities/joinClassNames'

export const colors = {
  neutral: 'neutral',
  gray: 'gray',
  yellow: 'yellow',
  'medium-gray': 'medium-gray',
  'bold-gray': 'bold-gray',
  danger: 'danger',
  brand: 'brand',
  element: 'element',
  'element-light': 'element-light',
  'element-extralight': 'element-extralight',
  'element-medium': 'element-medium',
  'element-bold': 'element-bold',
}

export type Colors = keyof typeof colors
type SVGElement = SVGProps<SVGSVGElement>

type LucideNames = keyof typeof dynamicIconImports

export interface IconProps extends Omit<SVGElement, 'fill' | 'ref'> {
  name?: IconName
  lucideName?: LucideNames
  color?: Colors
}

const Icon = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      name,
      lucideName,
      className,
      width = 16,
      height,
      strokeWidth = 1,
      color = 'white',
      ...props
    },
    ref,
  ) => {
    const IconComponent = useMemo(
      () =>
        lucideName
          ? lazy(dynamicIconImports[lucideName])
          : lazy(async () => ({
              // eslint-disable-next-line
              default: (await import(`./svg/${name}.svg`))
                .ReactComponent as FC<SVGElement>,
            })),
      [lucideName, name],
    )

    return (
      <Suspense>
        <IconComponent
          ref={ref}
          {...props}
          role="img"
          width={width}
          strokeWidth={strokeWidth}
          height={height || width}
          className={joinClassNames(styles[color], className)}
        />
      </Suspense>
    )
  },
)

Icon.displayName = 'Icon'

export default Icon
