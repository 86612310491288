import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'

import { FormItem, FormLabel, Loader, Pagination, Table } from 'components'
import { useCallback, useEffect, useMemo, useState } from 'react'

import EventDriver, { OccurrenceEvent } from 'services/event'

import styles from './styles.module.scss'

import { useSearchParams } from 'react-router-dom'
import {
  getEventDevice,
  getNormalizedDeviceTypeCode,
} from 'domains/event/screens/events/utilities'
import { maskedDateTimeHour } from 'utilities/date/date'

import { useForm } from 'react-hook-form'
import { EventHistoryFiltersSchema } from 'domains/event/screens/events/types'

import Select from 'components/Form/Select'
import { generateMonthlyFilters } from 'domains/event/screens/events/utilities/generateMonth'
import { Result } from 'services/types'
import { EmptyState } from 'domains/event/screens/events/components'

const Events = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [isFetching, setIsFetching] = useState(false)
  const [isError, setIsError] = useState(false)
  const [data, setData] = useState<Result<OccurrenceEvent>>({
    data: [],
    totalElements: 0,
  })

  const monthlyFilters = generateMonthlyFilters()

  const { setValue, watch } = useForm<{
    date: EventHistoryFiltersSchema
  }>({
    defaultValues: {
      date: monthlyFilters[0],
    },
  })

  const accountId = localStorage.getItem('accountId') || ''

  const columnHelper = createColumnHelper<OccurrenceEvent>()

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: 'event',
        header: 'Evento',
        size: 200,
        cell: (info) => {
          const { eventType } = info.getValue()

          return (
            <div className={styles.cell}>
              {eventType?.code} - {eventType?.description}
            </div>
          )
        },
      }),

      columnHelper.accessor((row) => row, {
        header: 'Origem',
        cell: ({ row }) => {
          const { auxiliary, originName } = row.original

          return (
            <div className={styles.cell}>
              <div>
                {`
          ${auxiliary?.code || ''} 
          ${originName && auxiliary?.code ? ' - ' : ' '} 
          ${originName || '--'}
        `}
              </div>
            </div>
          )
        },
      }),

      columnHelper.accessor('device', {
        header: 'Equipamento',
        cell: (info) => {
          const device = info.getValue()
          const command = info.row.original.command

          return getEventDevice(
            getNormalizedDeviceTypeCode(device?.deviceTypeCode),
            command?.id,
          )
        },
      }),
      columnHelper.accessor('partition', {
        header: 'Partição',
        cell: (info) => info.getValue()?.code || '--',
      }),
      columnHelper.accessor('datetime', {
        header: 'Data e hora',
        cell: (info) => <>{maskedDateTimeHour(info.getValue() || 0)}</>,
      }),
    ],
    [columnHelper],
  )

  const page = searchParams.get('page')

  const fetchEvents = useCallback(async () => {
    setIsFetching(true)

    const currentPage = Number(searchParams.get('page')) || 0
    const size = 15

    try {
      const events = await EventDriver.queryEvents({
        page: currentPage - 1,
        size,
        accountId: accountId || '',
        ...(watch('date') && { createdFrom: watch('date.createdFrom') }),
        ...(watch('date') && { createdTo: watch('date.createdTo') }),
      })
      setData(events)
    } catch {
      setIsError(true)
    } finally {
      setIsFetching(false)
    }
  }, [accountId, searchParams])

  const table = useReactTable({
    columns,
    data: data?.data || [],
    getCoreRowModel: getCoreRowModel(),
  })

  const handleRender = (
    data: unknown[] | undefined,
    isError: boolean,
    isFetching: boolean,
  ) => {
    if (!isFetching && data?.length) {
      return 'view'
    }

    if (isFetching) {
      return 'loading'
    }

    if (isError) {
      return 'error'
    }

    return 'empty'
  }

  useEffect(() => {
    if (page || watch('date')) {
      fetchEvents()
    }
  }, [page, watch('date')])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Eventos</h2>
      </div>

      <div className={styles.periodWrapper}>
        <FormItem className={styles.formItem}>
          <FormLabel className={styles.label}>Período</FormLabel>

          <Select.Root valueKey="name" data={monthlyFilters}>
            {({ unSelectedOptions }) => (
              <>
                <Select.Field
                  value={
                    monthlyFilters.find(
                      (month) => month.name === watch('date.name'),
                    )?.name
                  }
                />
                <Select.Group>
                  {unSelectedOptions?.map((option) => (
                    <Select.Option
                      key={option.name}
                      onClick={() => {
                        setValue('date', option)

                        setSearchParams({
                          ...Object.fromEntries([...searchParams]),
                          page: '1',
                        })
                      }}
                    >
                      {option.name}
                    </Select.Option>
                  ))}
                </Select.Group>
              </>
            )}
          </Select.Root>
        </FormItem>
      </div>

      {
        {
          view: (
            <>
              <Table.Root>
                <Table.Header className={styles.tableHeader}>
                  {table?.getHeaderGroups()?.map((headerGroup) => (
                    <Table.Row
                      className={styles.tableHeader}
                      key={headerGroup.id}
                    >
                      {headerGroup?.headers?.map((header) => (
                        <Table.ResizableColumn
                          key={header.id}
                          colSpan={header.colSpan}
                        >
                          {flexRender(
                            header?.column.columnDef.header,
                            header?.getContext(),
                          )}
                        </Table.ResizableColumn>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Header>

                {data?.totalElements && (
                  <Table.Body>
                    {table?.getRowModel()?.rows?.map((row) => (
                      <Table.Row key={row.id}>
                        {row?.getVisibleCells()?.map((cell) => (
                          <Table.Cell
                            key={cell.id}
                            className={styles[cell.column.id]}
                          >
                            {flexRender(
                              cell.column?.columnDef?.cell,
                              cell?.getContext(),
                            )}
                          </Table.Cell>
                        ))}
                      </Table.Row>
                    ))}
                  </Table.Body>
                )}
              </Table.Root>
            </>
          ),
          empty: <EmptyState />,
          error: <></>,
          loading: (
            <div className={styles.loader}>
              <Loader isVisible />
            </div>
          ),
        }[handleRender(data?.data, isError, isFetching)]
      }

      <Pagination
        className={styles.pagination}
        totalElements={data?.totalElements}
      />
    </div>
  )
}

export default Events
