export interface BaseItem {
  id: string
  name: string
  imgPath?: string
}

export type PaymentType = 'BANK_SLIP' | 'direct-debit' | 'credit-card'

export type PaymentMethod = {
  id: PaymentType
  name: string
}

export const paymentMethods: PaymentMethod[] = [
  { id: 'BANK_SLIP', name: 'Boleto' },
  { id: 'credit-card', name: 'Cartão de Crédito' },
  { id: 'direct-debit', name: 'Débito em conta' },
]
