import { useState, useEffect, Fragment, useMemo } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import styles from './Navbar.module.scss'
import { sidebarData } from './sidebarData'

import { ReactComponent as Logo } from 'components/Icon/svg/logo.svg'
import { ReactComponent as MicroLogo } from 'components/Icon/svg/micro-logo.svg'

import { User } from 'routes/components/Navbar/components'
import { handleLogoutAllTabs } from 'utilities/auth'
import { sendWhatsapp } from 'routes/components/Navbar/utilities/whatsapp'
import { Icon } from 'components'

export const NavBar = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [navbarOpen, setNavbarOpen] = useState(false)

  const defaultSubItemSelected = useMemo(() => {
    const array = location.pathname.split('/')

    return array[1]
  }, [location])

  const [subItemOpen, setSubItemOpen] = useState<string | undefined>(
    defaultSubItemSelected,
  )

  useEffect(() => {
    setSubItemOpen(() => defaultSubItemSelected)
  }, [location, navbarOpen])

  const handleOpenSubItems = (title?: string) => {
    if (subItemOpen === title) {
      return setSubItemOpen('')
    }

    setSubItemOpen(title)
  }

  return (
    <nav
      onMouseEnter={() => {
        setNavbarOpen(true)
      }}
      onMouseLeave={() => {
        setNavbarOpen(false)
      }}
      className={[styles.container, navbarOpen && styles.opened].join(' ')}
    >
      <div>
        <div className={styles.logo}>
          {navbarOpen ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}
            >
              <MicroLogo width={42} height={39} /> <Logo width={132} />
            </div>
          ) : (
            <MicroLogo width={42} height={39} />
          )}
        </div>
        <div className={styles.user}>
          <User />
        </div>
        <ul>
          {sidebarData.map((menu) => {
            const subItemsOpen = subItemOpen
              ? menu.path.includes(subItemOpen || '')
              : false

            const showSubItems = menu.subItems && navbarOpen

            return (
              <Fragment key={menu.path}>
                <li
                  onClick={() => {
                    if (!menu.subItems) {
                      navigate(menu.path)
                    }

                    handleOpenSubItems(menu.path)
                  }}
                  className={[
                    (menu.path === location.pathname || subItemsOpen) &&
                      styles.active,
                  ].join(' ')}
                >
                  <div>
                    <div>
                      {menu.icon}
                      <span>{menu.title}</span>
                    </div>
                  </div>

                  <div
                    className={[
                      styles.subItems,
                      showSubItems && subItemsOpen && styles.opened,
                    ].join(' ')}
                  >
                    {menu.subItems?.map((subItem) => {
                      return (
                        <NavLink
                          to={subItem.path}
                          key={subItem.path}
                          aria-label={subItem.title}
                          className={({ isActive }) =>
                            isActive ? styles.active : ''
                          }
                        >
                          {subItem.title}
                        </NavLink>
                      )
                    })}
                  </div>
                </li>
              </Fragment>
            )
          })}
        </ul>
      </div>
      <ul>
        <li>
          <div onClick={sendWhatsapp}>
            <div>
              <Icon name="tell-to-us" width={17} /> <span>Fale conosco</span>
            </div>
          </div>
        </li>

        <li>
          <div onClick={handleLogoutAllTabs}>
            <div>
              <Icon name="logoff" width={17} /> <span>Sair da conta</span>
            </div>
          </div>
        </li>
      </ul>
    </nav>
  )
}

export default NavBar
