import { useMutation } from '@tanstack/react-query'
import { AccountService } from 'services/account'
import { PaymentMethodRequest } from 'services/account/types'

async function editPaymentMethod(request: PaymentMethodRequest) {
  return await AccountService.editPaymentMethod(request)
}

export const usePutPaymentMethod = () => {
  const { isLoading, mutate, error, status } = useMutation(
    (request: PaymentMethodRequest) => editPaymentMethod(request),
    {
      retry: false,
    },
  )

  return { isLoading, mutate, error, status }
}
