import { useQuery } from '@tanstack/react-query'
import { httpClient } from 'config/axios'

import { PartitionsWithEquipments } from './types'
import buildUrl from 'utilities/buildUrl'
import { endpoints } from './endpoints'

const getZonesWithEquipments = async (
  accountId?: string,
): Promise<PartitionsWithEquipments> => {
  const response = await httpClient.get(
    buildUrl({
      route: endpoints.getPartitionsWithEquipments,
      params: { accountId },
    }),
  )

  return response.data
}

export function useGetZoneWithEquipments(accountId?: string) {
  return useQuery({
    queryKey: ['accountId', accountId],
    queryFn: async () => await getZonesWithEquipments(accountId),
    refetchOnWindowFocus: false,
    enabled: !!accountId,
  })
}
