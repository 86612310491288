import { Button, Combobox, ComboboxItem, Instruction, Loader } from 'components'
import { CreditCardForm } from 'domains/account/paymentMethod/components/CreditCardForm/CreditCardForm'
import { DirectDebitForm } from 'domains/account/paymentMethod/components/DirectDebitForm/DirectDebitForm'
import { RegisteredPayment } from 'domains/account/paymentMethod/components/RegisteredPayment/RegisteredPayment'
import { BaseItem, paymentMethods } from 'domains/account/types'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PaymentMethodRequest, PaymentMethodType } from 'services/account/types'
import { Patrimony } from 'services/patrimony/types'
import { useGetUserPatrimonies } from 'shared/hooks/services/patrimony'

import styles from './PaymentMethod.module.scss'
import { MutateOptions } from '@tanstack/react-query'
import { usePostPaymentMethod } from 'shared/hooks/services/account'
import { usePutPaymentMethod } from 'shared/hooks/services/account/usePutPaymentMethod'
import { ReactComponent as Logo } from 'assets/svg/logo.svg'

export const PaymentMethod: React.FunctionComponent = () => {
  const comboboxPaymentMethods: ComboboxItem<BaseItem>[] = paymentMethods.map(
    (brand) => ({
      label: 'name',
      value: brand,
    }),
  )
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<ComboboxItem<BaseItem>>()
  const [selectedPatrimony, setSelectedPatrimony] = useState<Patrimony>()
  const [isEditing, setIsEditing] = useState(false)

  const { userId } = useParams()

  const { patrimonies, isLoading, isFetching, refetch } = useGetUserPatrimonies(
    userId || '',
  )

  const saveMutation = usePostPaymentMethod()
  const editMutation = usePutPaymentMethod()

  const onSubmit = () => {
    const request: PaymentMethodRequest = {
      paymentMethodType: 'BANK_SLIP',
      accountId: selectedPatrimony?.account.id || '',
    }

    const options: MutateOptions<void, unknown, PaymentMethodRequest, unknown> =
      {
        onSuccess: () => {
          onSubmitSuccess()
        },
      }

    if (selectedPatrimony?.paymentMethod?.paymentMethodType) {
      editMutation.mutate(request, options)
    } else {
      saveMutation.mutate(request, options)
    }
  }

  const patrimoniesAccountAdmin = useMemo(() => {
    return patrimonies.filter((patrimony) => patrimony.accountAdmin)
  }, [patrimonies])

  const comboboxPatrimonies = useMemo(() => {
    const comboboxItems: ComboboxItem<Patrimony>[] =
      patrimoniesAccountAdmin.map((patrimony) => {
        return {
          label: 'name',
          value: {
            ...patrimony,
            name: `${patrimony.name}${patrimony?.paymentMethod ? '' : '*'}`,
          },
        }
      })
    return comboboxItems
  }, [patrimoniesAccountAdmin])

  const showPaymentMethodForm = useMemo((): boolean => {
    if (!selectedPatrimony?.paymentMethod) return true

    if (selectedPatrimony?.paymentMethod?.paymentMethodType === 'NONE')
      return true

    if (isEditing) return true

    return false
  }, [selectedPatrimony, isEditing])

  useEffect(() => {
    if (patrimonies) {
      setSelectedPatrimony(patrimonies[0])
    }
  }, [patrimonies])

  const onSubmitSuccess = () => {
    refetch()
    setIsEditing(false)
  }

  const paymentOptions: Record<PaymentMethodType, ComboboxItem<BaseItem>[]> = {
    NONE: [],
    BANK_SLIP: paymentMethods.map((paymentOption) => ({
      label: 'name',
      value: paymentOption,
    })),
    CREDIT_CARD: [paymentMethods[1]].map((paymentOption) => ({
      label: 'name',
      value: paymentOption,
    })),
    DIRECT_DEBIT: [paymentMethods[1], paymentMethods[2]].map(
      (paymentOption) => ({ label: 'name', value: paymentOption }),
    ),
  }

  if (isLoading || isFetching)
    return (
      <div className={styles.container}>
        <Loader isVisible />
      </div>
    )

  return (
    <>
      <div className={styles.container}>
        <header className={styles.header}>
          <Logo />
        </header>

        <div className={styles.wrapper}>
          <h2 className={styles.title}>Conta</h2>
          <p className={styles.info}>
            Adicione uma nova forma de pagamento para a sua conta Alarme 365.
          </p>
          <Instruction content="Só será aceito um método de pagamento por patrimônio" />
          <Combobox
            label="Selecione o patrimônio:"
            items={comboboxPatrimonies}
            value={comboboxPatrimonies[0]}
            renderNotification={patrimoniesAccountAdmin.some(
              (patrimony) => !patrimony?.paymentMethod,
            )}
            getSelected={(item) => {
              if (item?.value) {
                setSelectedPatrimony(item.value)
              }
            }}
          />

          {selectedPatrimony?.paymentMethod && !showPaymentMethodForm ? (
            <>
              <div>
                <p className={styles.info}>Método de pagamento cadastrado</p>
              </div>
              <RegisteredPayment
                registeredPaymentMethod={selectedPatrimony?.paymentMethod}
                onEdit={() => setIsEditing(true)}
              />
            </>
          ) : (
            <>
              <Combobox
                label="Selecione o tipo:"
                items={
                  isEditing
                    ? paymentOptions[
                        selectedPatrimony?.paymentMethod?.paymentMethodType ||
                          'BANK_SLIP'
                      ]
                    : comboboxPaymentMethods
                }
                value={selectedPaymentMethod}
                getSelected={(selected) => {
                  if (selected) {
                    setSelectedPaymentMethod(selected)
                  }
                }}
              />

              {selectedPaymentMethod?.value.id === 'credit-card' && (
                <CreditCardForm
                  accountId={selectedPatrimony?.account.id || ''}
                  onSubmitSuccess={onSubmitSuccess}
                  paymentMethodType={
                    selectedPatrimony?.paymentMethod?.paymentMethodType
                  }
                />
              )}

              {selectedPaymentMethod?.value.id === 'direct-debit' && (
                <DirectDebitForm
                  accountId={selectedPatrimony?.account.id || ''}
                  onSubmitSuccess={onSubmitSuccess}
                  paymentMethodType={
                    selectedPatrimony?.paymentMethod?.paymentMethodType
                  }
                />
              )}

              {selectedPaymentMethod?.value.id === 'BANK_SLIP' && (
                <Button title="Salvar" onClick={onSubmit} htmlType="submit" />
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}
