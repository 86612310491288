import { Icon, ProtectedImage } from 'components'

import styles from './styles.module.scss'
import { parseCookies } from 'nookies'

const User = () => {
  const { profileImageId, email } = parseCookies()

  return (
    <div className={styles.container}>
      <div>
        {profileImageId ? (
          <ProtectedImage imageId={profileImageId} />
        ) : (
          <Icon name="avatar" width={40} height={40} />
        )}
      </div>
      <div className={styles.principalInfos}>
        <span>
          {email?.split('@')[0]}
          <br />
          <small>@{email?.split('@')[1]}</small>
        </span>
      </div>
    </div>
  )
}

export default User
