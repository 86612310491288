import { Sort } from 'services/types'

import { AccountResponse } from 'services/account/types'

export enum DeviceTypeCodeOptions {
  RemoteControl = '0',
  PassiveInfraPhotoRedSensor = '2',
  ReedSwitch = '4',
  WirelessSiren = '5',
}

export enum PartitionStatus {
  'ARMED' = 'A',
  'ARMED_STAY' = 'S',
  'DISARMED' = 'D',
}

export type PartitionResponse = {
  id: string
  centralId: string
  name: string
  code: string
  status: PartitionStatus
  securityQuestion?: string
  securityAnswer?: string
  securityCoercionAnswer?: string
  createdAt: number
  updatedAt: number
  aggregatedName?: string
}

export enum DeviceType {
  RemoteControl = '0',
  PassiveInfraRedSensor = '1',
  PassiveInfraPhotoRedSensor = '2',
  ReedSwitch = '4',
  WirelessSiren = '5',
  Central = '99',
}

const occurrenceTypes = {
  ALARM: 'ALARM',
  RESCUE: 'RESCUE',
  FAILURE: 'FAILURE',
  FINISHED: 'FINISHED',
  IMMINENT: 'IMMINENT',
  EMERGENCY: 'EMERGENCY',
  COMMERCIAL: 'COMMERCIAL',
  SCHEDULED: 'SCHEDULED',
  MANUAL: 'MANUAL',
}

export type OccurrenceTypes = keyof typeof occurrenceTypes

export enum OccurrenceTypeName {
  RESCUE = 'RESCUE',
  EMERGENCY = 'EMERGENCY',
  ALARM = 'ALARM',
  IMMINENT = 'IMMINENT',
  FAILURE = 'FAILURE',
  COMMERCIAL = 'COMMERCIAL',
  MANUAL = 'MANUAL',
  SCHEDULED = 'SCHEDULED',
}

export interface CustomerResponse {
  id: string
  document: string
  name: string
  secondName: string
  personType: string
  tags?: []
  deviceId?: string
  createdAt: number
  updatedAt: number
  active: boolean
  activeDateTime: number
  costCenter?: string
}

export type DetectLabelBoundingBox = {
  width: number
  height: number
  left: number
  top: number
}

export type DetectLabelInstance = {
  id: string
  confidence: number
  boundingBox: DetectLabelBoundingBox
}

export type DetectLabelParent = {
  name: string
}

export type DetectLabel = {
  name: string
  confidence: number
  instances: DetectLabelInstance[]
  parents: DetectLabelParent[]
}

export type SensorType = 1 | 2 | 3 | 4 | 5

export interface EventType {
  id: string
  code: string
  description: string
  occurrenceTypeId: string
  occurrenceTypeName: OccurrenceTypeName
}

export type EventCentral = {
  id: string
  code: string
  name: string
}

export type EventAccount = {
  id: string
  code: string
}

export type EventDevice = {
  id: string
  code: string
  name?: string
  deviceTypeCode: DeviceType
  sensorType?: SensorType
  partitions?: string[] | null | undefined
}

export type EventCommand = {
  id: string
  userId: string
}

export type EventPartition = {
  id: string
  code: string
  name: string
}

export type EventImage = {
  id: string
  labels: DetectLabel[]
}

export type EventCombination = {
  id: string
  code: string
  name: string
}

export type EventDeviceIdCode = {
  id: string
  code: string
}

const eventIgnoredReason = {
  DISABLE_DEVICE: 'DISABLE_DEVICE',
  INACTIVE_ACCOUNT: 'MAINTENANCE_MODE',
  MAINTENANCE_MODE: 'INACTIVE_ACCOUNT',
}

export type EventIgnoredReason = keyof typeof eventIgnoredReason

export interface OccurrenceEvent {
  id: string
  occurrenceId?: string
  datetime: number
  originName?: string
  eventType: EventType
  finishedAt?: number
  image?: EventImage
  account?: EventAccount
  auxiliary?: {
    code?: string
    type?: string
  }
  central?: EventCentral
  device?: EventDevice
  command?: EventCommand
  partition?: EventPartition
  partitions?: EventPartition[]
  combination?: EventCombination
  ignoredReason?: EventIgnoredReason
}

export type EventQuery = {
  accountId: string
  eventCode?: string
  occurrenceTypeId?: string
  deviceTypeCode?: DeviceTypeCodeOptions
  createdFrom?: number
  createdTo?: number
} & {
  page?: number
  size?: number
}

export type EventTypeQuery = {
  name?: string
  description?: string
  eventRuleId?: string
  offset?: number
  recordsPerPage?: number
  updatedTo?: number
  updatedFrom?: number
} & Partial<Sort>

export type EventTypeResponse = {
  id: string
  name: string
  description: string
  eventRuleId: string
  notify: boolean
  createOccurrence: boolean
  createOccurrenceType: string | null
  finishOccurrenceType: string | null
  mitigateEventType: string | null
  createdAt: number
  updatedAt: number
  aggregatedCodeDescription: string
  auxiliaryType: string
}

export type Query = Partial<EventQuery> & {
  fields?: (keyof OccurrenceEvent)[]
  account?: (keyof AccountResponse)[]
}

export type QueryEvent = Partial<
  Omit<OccurrenceEvent, 'eventType' | 'account'>
> & {
  eventType?: Omit<EventType, 'occurrenceTypeName'> & {
    occurrenceTypeName: OccurrenceTypes
  }
  originName: string
  partition?: Partial<PartitionResponse>
  account?: Partial<AccountResponse>
  customer?: CustomerResponse
}
