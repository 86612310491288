import React, { ChangeEvent, forwardRef, useState, ForwardedRef } from 'react'
import { ReactComponent as IconError } from 'assets/svg/error.svg'
import { ReactComponent as OpenedEye } from './assets/svg/openedEye.svg'
import { ReactComponent as ClosedEye } from './assets/svg/closedEye.svg'

import styles from './Input.module.scss'

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  id?: string
  value?: string
  defaultValue?: string
  label?: string
  name?: string
  password?: boolean
  placeholder?: string
  errorMessage?: string
  maxLength?: number
  innerRef?: ForwardedRef<HTMLInputElement>
  disabled?: boolean
  wrapperClassName?: string
  onChange?(event: ChangeEvent<HTMLInputElement>): void
  onKeyPress?(event: React.KeyboardEvent<HTMLInputElement>): void
}

const CustomInput: React.FC<InputProps> = (props: InputProps) => {
  const {
    id,
    className,
    wrapperClassName,
    value,
    defaultValue,
    name,
    label,
    errorMessage,
    password,
    placeholder,
    maxLength,
    max,
    innerRef,
    disabled,
    readOnly,
    type = 'text',
    onChange,
    onKeyPress,
    autoComplete,
    onBlur,
    inputMode,
  } = props

  const [showPassword, setShowPassword] = useState(false)

  const handlePasswordType = () => {
    if (password && !showPassword) {
      return 'password'
    }
    return type
  }

  return (
    <div
      className={[styles.inputWrapper, wrapperClassName]
        .filter(Boolean)
        .join(' ')}
    >
      {label && (
        <label htmlFor={id || 'input'} className={styles.label}>
          {label}
        </label>
      )}
      <div className={styles.innerInputWrapper}>
        <input
          autoComplete={autoComplete}
          id={id}
          name={name || 'input'}
          className={[
            styles.input,
            password && styles.inputPassword,
            errorMessage && styles.error,
            className,
          ].join(' ')}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          placeholder={placeholder || ''}
          maxLength={maxLength}
          max={max}
          type={handlePasswordType()}
          ref={innerRef}
          onKeyPress={onKeyPress}
          disabled={disabled}
          readOnly={readOnly}
          onBlur={onBlur}
          aria-label={label}
          inputMode={inputMode}
        />
        {password && (
          <span className={styles.showPassword}>
            <i
              className={[
                styles.inputPasswordIcon,
                showPassword && styles.closedEye,
              ].join(' ')}
              data-testid="input-password-icon"
              onClick={() => setShowPassword((prev) => !prev)}
            >
              {showPassword ? (
                <ClosedEye data-testid="closed-eye" />
              ) : (
                <OpenedEye data-testid="opened-eye" />
              )}
            </i>
          </span>
        )}
      </div>
      {errorMessage && (
        <div className={styles.errorWrapper}>
          <span className={styles.errorInnerWrapper}>
            <IconError className={styles.errorIcon} />
            <p className={styles.errorText}>{errorMessage}</p>
          </span>
        </div>
      )}
    </div>
  )
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, ref: ForwardedRef<HTMLInputElement>) => (
    <CustomInput {...props} innerRef={ref} />
  ),
)

Input.displayName = 'Input'

export default Input
