import { AxiosInstance } from 'axios'
import { httpClient } from 'config/axios'

interface ImageDriver {
  getImage(imageId: string): Promise<string>
}

export class ImageDriverImpl implements ImageDriver {
  public constructor(private readonly HttpClient: AxiosInstance = httpClient) {}

  public async getImage(imageId: string): Promise<string> {
    const response = await this.HttpClient.get(`/image/${imageId}/file`, {
      responseType: 'blob',
    })

    // eslint-disable-next-line
    return URL.createObjectURL(response.data);
  }
}

export default new ImageDriverImpl()
