import { ComboboxItem } from '../Combobox'

import styles from '../Combobox.module.scss'
import { getItemValue } from '../utilities'

interface Item {
  id: string
  type?: string
}

type ComboboxItemComponentProps<T> = {
  item: ComboboxItem<T>
  itemId: string
  itemDisabled: boolean
  onSelectItem: (item: ComboboxItem<T>) => void
  itemLabel?: string | ((item: ComboboxItem<T> | string) => string)
}

export function ComboboxItemComponent<T extends Item>({
  item,
  itemId,
  itemDisabled,
  onSelectItem,
  itemLabel,
}: ComboboxItemComponentProps<T>): JSX.Element {
  const label =
    itemLabel && typeof itemLabel !== 'string' ? itemLabel(item) : itemLabel

  return (
    <li
      className={[styles.item, itemDisabled && styles.itemDisabled]
        .filter(Boolean)
        .join(' ')}
      data-combobox-item
      onClick={() => {
        if (!itemDisabled) {
          onSelectItem(item)
        }
      }}
      key={itemId}
    >
      <div id={itemId} className={styles.itemTextContainer}>
        <>{getItemValue(item)}</>
      </div>
      {(itemDisabled || itemLabel) && (
        <div>
          <span>{itemDisabled ? 'Em uso' : label}</span>
        </div>
      )}
    </li>
  )
}
