import { Loader } from 'components'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { GrantTypes } from 'services/auth'
import { ReactComponent as Alert } from '../../assets/svg/alert.svg'
import { usePostAuth } from 'shared/hooks/services/auth'

import styles from './styles.module.scss'

const AppAuthentication: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const { clientId, userId } = useParams()
  const { mutate, isLoading, credentials, error } = usePostAuth()

  useEffect(() => {
    mutate({
      grantType: GrantTypes.UbisafeCode,
      realm: 'ORSEGUPS',
      clientId,
    })
  }, [mutate, clientId])

  useEffect(() => {
    if (credentials) {
      navigate(`/payment-method/${String(userId)}`)
    }
  }, [credentials, navigate, userId])

  return (
    <div className={styles.container}>
      <>
        <Loader isVisible={isLoading} />
        {error && (
          <div className={styles.errorWrapper}>
            <Alert />
            <h2 className={styles.unauthorizedTitle}>Acesso não autorizado</h2>
            <p className={styles.unauthorizedInfo}>
              Retorne ao aplicativo e tente novamente
            </p>
          </div>
        )}
      </>
    </div>
  )
}

export default AppAuthentication
