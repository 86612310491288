import { DOCUMENT_CPF_LENGTH } from 'utilities/mask/document/constants'

export const formatCnpj = (value: string): string => {
  const newValue = value.replace(/\D/g, '')
  let cnpjWithMask
  const inputLenght = newValue.length
  switch (true) {
    case inputLenght >= 3 && inputLenght <= 5:
      cnpjWithMask = newValue.replace(/(\d{2})(\d{1,})/g, '$1.$2')
      break
    case inputLenght >= 6 && inputLenght <= 8:
      cnpjWithMask = newValue.replace(/(\d{2})(\d{3})(\d{1,})/g, '$1.$2.$3')
      break
    case inputLenght >= 9 && inputLenght <= 12:
      cnpjWithMask = newValue.replace(
        /(\d{2})(\d{3})(\d{3})(\d{1,})/g,
        '$1.$2.$3/$4',
      )
      break
    default:
      cnpjWithMask = newValue.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{1,})/g,
        '$1.$2.$3/$4-$5',
      )
  }
  return cnpjWithMask
}

export const formatCpf = (value: string): string => {
  const newValue = value.replace(/\D/g, '')
  let cpfWithMask
  const inputLenght = newValue.length

  switch (true) {
    case inputLenght >= 4 && inputLenght <= 6:
      cpfWithMask = newValue.replace(/(\d{3})(\d{1,})/g, '$1.$2')
      break
    case inputLenght >= 7 && inputLenght <= 9:
      cpfWithMask = newValue.replace(/(\d{3})(\d{3})(\d{1,})/g, '$1.$2.$3')
      break
    default:
      cpfWithMask = newValue.replace(
        /(\d{3})(\d{3})(\d{3})(\d{1,})/g,
        '$1.$2.$3-$4',
      )
  }
  return cpfWithMask
}

export const formatDocument = (clientDocument: string) =>
  clientDocument.length === DOCUMENT_CPF_LENGTH
    ? formatCpf(clientDocument)
    : formatCnpj(clientDocument)
