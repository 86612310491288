import { useQuery } from '@tanstack/react-query'
import buildUrl from 'utilities/buildUrl'

import { httpClient } from 'config/axios'
import { endpoints } from 'services/contacts/endpoints'
import { ContactsResponse } from 'services/contacts/types'

export const findContacts = async (
  accountId: string,
): Promise<ContactsResponse> => {
  const response = await httpClient.get(
    buildUrl({
      route: endpoints.findAll,
      params: { accountId },
    }),
  )

  return response.data as ContactsResponse
}

export function useGetContacts(accountId: string) {
  return useQuery({
    queryKey: ['accountId', accountId],
    queryFn: async () => await findContacts(accountId),
    refetchOnWindowFocus: false,
    retry: 0,
  })
}
