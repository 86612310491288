import { ComponentProps, forwardRef, ReactElement } from 'react'
import styles from './styles.module.scss'
import { Loader } from 'components'

interface IconButtonProps extends ComponentProps<'button'> {
  isLoading?: boolean
  children: ReactElement
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    { className, type = 'button', isLoading = false, children, ...props },
    ref,
  ) => (
    <button
      ref={ref}
      {...props}
      type={type}
      className={[
        styles.button,
        className,
        isLoading ? styles.loading : '',
      ].join(' ')}
      disabled={isLoading || props.disabled}
    >
      {isLoading ? <Loader isVisible /> : children}
    </button>
  ),
)

IconButton.displayName = 'Button'

export default IconButton
