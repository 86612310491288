import Axios, { AxiosError, AxiosInstance } from 'axios'
import {
  AxiosRequestInterceptor,
  AxiosResponseInterceptor,
} from 'config/axios/interceptors'
import { getBaseUrl } from 'config/axios/utilities'

export const httpClient: AxiosInstance = Axios.create({
  baseURL: getBaseUrl(),
})

httpClient.interceptors.request.use(
  (config) => AxiosRequestInterceptor.onFulfilled(config),
  (error) => AxiosRequestInterceptor.onRejected(error),
)

httpClient.interceptors.response.use(
  (config) => AxiosResponseInterceptor.onFulfilled(config),
  (error: AxiosError) => AxiosResponseInterceptor.onRejected(error),
)
