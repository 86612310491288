import styles from './Header.module.scss'
import Accordion from 'components/Accordion'
import { ButtonV2, Info } from 'components'
import { useFindAccount } from 'services/account'
import { useContext, useState } from 'react'

import { formatCpf } from 'utilities/mask/document'
import { formatPostalCode } from 'utilities/mask/postalCode'
import { maskPhoneNumber } from 'utilities/mask/phone'

import { ChangeAccount } from './components'
import { UserContext } from 'shared/contexts/user/user'

export function Header(): JSX.Element {
  const accountId = localStorage.getItem('accountId') || ''

  const { accounts, fetchUserAccounts } = useContext(UserContext)

  const [isVisible, setIsVisible] = useState(false)

  const { data, isFetching } = useFindAccount(accountId || accounts?.[0] || '')

  return (
    <div className={styles.header}>
      <ChangeAccount
        isVisible={isVisible}
        onClose={() => {
          setIsVisible(false)
          fetchUserAccounts()
        }}
      />

      <Accordion.Root>
        <Accordion.Item>
          {({ isOpen }) => (
            <>
              <Accordion.Header className={styles.headerContainer}>
                <Accordion.Trigger className={styles.title}>
                  <Accordion.Icon />
                  Conta
                  {!isOpen &&
                    !isFetching &&
                    data &&
                    ` ${data?.account.code} • ${data?.patrimony.address}, ${data?.patrimony.number}, ${data?.patrimony.state.toLowerCase()}/${data?.patrimony.acronym}`}
                </Accordion.Trigger>

                <ButtonV2
                  onClick={() => setIsVisible(true)}
                  className={styles.account}
                  appearance="tertiary"
                >
                  Trocar conta
                </ButtonV2>
              </Accordion.Header>

              {!isFetching && data && (
                <Accordion.Content>
                  <div className={styles.content}>
                    <Info.Root>
                      <Info.Title>Nome do cliente</Info.Title>
                      <Info.Content>{data?.customer.name}</Info.Content>
                    </Info.Root>

                    <Info.Root>
                      <Info.Title>Código da conta</Info.Title>
                      <Info.Content>{data?.account.code}</Info.Content>
                    </Info.Root>

                    <Info.Root>
                      <Info.Title>Documento</Info.Title>
                      <Info.Content>
                        {formatCpf(data?.customer.document || '')}
                      </Info.Content>
                    </Info.Root>

                    <Info.Root>
                      <Info.Title>Endereço</Info.Title>
                      <Info.Content>
                        {`${data?.patrimony.address}, ${data?.patrimony.number}`}
                      </Info.Content>
                    </Info.Root>

                    <Info.Root>
                      <Info.Title>Cidade/UF</Info.Title>
                      <Info.Content
                        className={styles.title}
                      >{`${data?.patrimony.state.toLowerCase()}/${data?.patrimony.acronym}`}</Info.Content>
                    </Info.Root>

                    <Info.Root>
                      <Info.Title>CEP</Info.Title>
                      <Info.Content>
                        {formatPostalCode(data?.patrimony.postalCode || '')}
                      </Info.Content>
                    </Info.Root>

                    <Info.Root>
                      <Info.Title>Contato responsável</Info.Title>
                      <Info.Content>
                        {data?.patrimony.responsible || '-'}
                      </Info.Content>
                    </Info.Root>

                    <Info.Root>
                      <Info.Title>E-mail</Info.Title>
                      <Info.Content>-</Info.Content>
                    </Info.Root>

                    <Info.Root>
                      <Info.Title>Telefone</Info.Title>
                      <Info.Content>
                        {maskPhoneNumber(
                          `${data?.patrimony.phone.provinceCode}${data?.patrimony.phone.phoneNumber}`,
                        )}
                      </Info.Content>
                    </Info.Root>
                  </div>
                </Accordion.Content>
              )}
            </>
          )}
        </Accordion.Item>
      </Accordion.Root>
    </div>
  )
}
