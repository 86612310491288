import { destroyCookie, setCookie } from 'nookies'
import { BroadcastChannel } from 'broadcast-channel'

const logoutChannel = new BroadcastChannel('logout')

interface Cookies {
  userId: string
  accessToken: string
  refreshToken: string
  email: string
  profileImageId: string
}

const handleDestroyCookies = () => {
  destroyCookie(undefined, 'userId')
  destroyCookie(undefined, 'accessToken')
  destroyCookie(undefined, 'refreshToken')
}

export const handleSetCookies = ({
  userId,
  accessToken,
  refreshToken,
  email,
  profileImageId,
}: Cookies) => {
  handleDestroyCookies()

  setCookie(undefined, 'userId', userId, {
    maxAge: 60 * 600,
  })
  setCookie(undefined, 'accessToken', accessToken, {
    maxAge: 60 * 300,
  })
  setCookie(undefined, 'refreshToken', refreshToken, {
    maxAge: 60 * 300,
  })

  setCookie(undefined, 'profileImageId', profileImageId, {
    maxAge: 60 * 300,
  })

  setCookie(undefined, 'email', email, {
    maxAge: 60 * 300,
  })
}

const handleLogout = () => {
  handleDestroyCookies()

  if (!window.location.href.includes('/login')) {
    window.location.href = '/login'
  }
}

export const handleLogoutAllTabs = () => {
  handleLogout()

  logoutChannel.postMessage('logout')

  logoutChannel.onmessage = () => {
    handleLogout()
  }
}
