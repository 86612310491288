import { ReactComponent as Exclamation } from './assets/svg/exclamation.svg'

import styles from './Instruction.module.scss'

type InfoInstructionProps = {
  content: string
}

export const Instruction = ({ content }: InfoInstructionProps) => {
  return (
    <div className={styles.container}>
      <div>
        <Exclamation width={16} height={16} className={styles.icon} />
      </div>
      <p className={styles.content}>{content}</p>
    </div>
  )
}
