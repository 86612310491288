import {
  ComponentProps,
  cloneElement,
  createContext,
  useContext,
  useEffect,
  useRef,
  MouseEvent as ReactMouseEvent,
  RefObject,
  ReactElement,
  useState,
} from 'react'
import ReactDOM from 'react-dom'

import joinClassNames from 'utilities/joinClassNames'

import styles from './styles.module.scss'
import { useOnClickOutside } from 'shared/hooks'

interface TooltipContextProps {
  isVisible: boolean
  handleShow: () => void
  handleHidden: () => void
  triggerRef: RefObject<HTMLButtonElement>
}

const TooltipContext = createContext<TooltipContextProps>({
  isVisible: false,
  handleShow: () => null,
  handleHidden: () => null,
  triggerRef: { current: null },
})

type TooltipProps = ComponentProps<'div'>

const Root = (props: TooltipProps) => {
  const [isVisible, setIsVisible] = useState(false)
  const triggerRef = useRef(null)

  const handleHidden = () => {
    setIsVisible(false)
  }

  const handleShow = () => {
    setIsVisible(true)
  }

  return (
    <TooltipContext.Provider
      value={{ isVisible, handleShow, handleHidden, triggerRef }}
      {...props}
    />
  )
}

type TriggerProps = ComponentProps<'button'> & {
  asChild?: boolean
}

const Trigger = ({
  onMouseEnter,
  onMouseLeave,
  asChild,
  children,
  ...props
}: TriggerProps) => {
  const { handleHidden, handleShow, triggerRef } = useContext(TooltipContext)

  const handleMouseEnter = (event: ReactMouseEvent<HTMLButtonElement>) => {
    onMouseEnter?.(event)
    handleShow()
  }

  const handleMouseLeave = (event: ReactMouseEvent<HTMLButtonElement>) => {
    onMouseLeave?.(event)
    handleHidden()
  }

  return cloneElement(children as ReactElement, {
    ref: triggerRef,
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
    ...props,
  })
}

type Position = 'left' | 'right' | 'top' | 'bottom'

type ContentProps = ComponentProps<'div'> & {
  offsetX?: number
  offsetY?: number
  position?: Position
  size?: 'sm' | 'md' | 'lg'
}

const Content = ({
  children,
  size = 'md',
  offsetX = 12,
  offsetY = 12,
  position = 'left',
  className,
  ...props
}: ContentProps) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const { isVisible, triggerRef, handleHidden } = useContext(TooltipContext)

  useEffect(() => {
    const handleUpdatePosition = () => {
      if (triggerRef.current && contentRef.current) {
        const padding = 12

        const triggerRect = triggerRef.current.getBoundingClientRect()
        const contentRect = contentRef.current.getBoundingClientRect()

        const positions: Record<Position, { top: number; left: number }> = {
          left: {
            top: triggerRect.top + window.scrollY + offsetY,
            left:
              triggerRect.left + window.scrollX - contentRect.width - offsetX,
          },
          right: {
            top: triggerRect.top + window.scrollY + offsetY,
            left: triggerRect.right + window.scrollX + offsetX,
          },
          top: {
            top:
              triggerRect.top -
              triggerRect.height -
              window.scrollY -
              contentRect.height -
              offsetY,
            left: triggerRect.left + window.scrollX + offsetX,
          },
          bottom: {
            top: triggerRect.top + triggerRect.height + offsetY,
            left: triggerRect.left - contentRect.width / 2 + offsetX,
          },
        }

        let { top, left } = positions[position]

        top = Math.max(
          padding,
          Math.min(top, window.innerHeight - contentRect.height - padding),
        )
        left = Math.max(
          padding,
          Math.min(left, window.innerWidth - contentRect.width - padding),
        )

        contentRef.current.style.top = `${top}px`
        contentRef.current.style.left = `${left}px`
      }
    }

    handleUpdatePosition()

    window.addEventListener('resize', handleUpdatePosition)
    window.addEventListener('scroll', handleUpdatePosition)

    return () => {
      window.removeEventListener('resize', handleUpdatePosition)
      window.removeEventListener('scroll', handleUpdatePosition)
    }
  }, [isVisible, triggerRef, position, offsetX, offsetY])

  useOnClickOutside(contentRef, handleHidden)

  return isVisible
    ? ReactDOM.createPortal(
        <div
          ref={contentRef}
          className={joinClassNames(styles.content, styles[size], className)}
          {...props}
        >
          {children}
        </div>,
        document.body,
      )
    : null
}

const Tooltip = {
  Root,
  Trigger,
  Content,
}

export default Tooltip
