import { BaseItem } from 'domains/account/types'

import itau from '../assets/images/banks/itau.png'
import caixa from '../assets/images/banks/caixa.png'
import bancoDoBrasil from '../assets/images/banks/bancoDoBrasil.png'
import banrisul from '../assets/images/banks/banrisul.png'
import bradesco from '../assets/images/banks/bradesco.png'
import santander from '../assets/images/banks/santander.png'

export const banks: BaseItem[] = [
  {
    id: '001',
    name: 'Banco do Brasil',
    imgPath: bancoDoBrasil,
  },
  {
    id: '041',
    name: 'Banrisul',
    imgPath: banrisul,
  },
  {
    id: '104',
    name: 'Caixa Econômica Federal',
    imgPath: caixa,
  },
  {
    id: '237',
    name: 'Bradesco',
    imgPath: bradesco,
  },
  {
    id: '341',
    name: 'Itaú',
    imgPath: itau,
  },
  {
    id: '033',
    name: 'Santander',
    imgPath: santander,
  },
]
