/* eslint-disable no-bitwise */
/* eslint-disable no-cond-assign */

import dayjs from 'dayjs'
import { CreditCardSchema } from 'domains/account/paymentMethod/schemas/creditCard/creditCardSchema'
import Joi from 'joi'

import {
  CHARCODE_0,
  CREDIT_CARD_EXPIRY_DATE_LENGTH,
  CURRENT_MILLENIUM,
  FORMAT_ISVALID,
  MAPPING_EVEN,
  MINIMUM_CVV_LENGTH,
} from '../../constants/creditCard'

const removeMask = (value: string) => value.replace(/\D/g, '')

export const validateCreditCardExpiryDate: Joi.CustomValidator<string> = (
  value,
  helper,
) => {
  const unmaskedValue = removeMask(value)

  if (unmaskedValue.length < CREDIT_CARD_EXPIRY_DATE_LENGTH) {
    return helper.error('string.min')
  }
  const expiryDateMonth = Number(unmaskedValue.slice(0, 2)) - 1
  const expiryDateYear = Number(unmaskedValue.slice(2, 4)) + CURRENT_MILLENIUM

  const date = dayjs().set('month', expiryDateMonth).set('year', expiryDateYear)

  if (!dayjs().isBefore(date)) {
    return helper.error('string.invalid')
  }

  return value
}

function getLuhnRemainder(value: string) {
  let length = value.length
  let accumulator = 0
  let bit = 0

  while (length-- > 0) {
    accumulator += (bit ^= 1)
      ? value.charCodeAt(length) - CHARCODE_0
      : MAPPING_EVEN[value.charCodeAt(length) - CHARCODE_0]
  }

  return accumulator % 10
}

const luhn = (value: string) => {
  if (!value.match(FORMAT_ISVALID)) {
    return false
  }

  return getLuhnRemainder(value) === 0
}

function getCreditCardNumberMaxLength(cardBrandName: string) {
  switch (cardBrandName) {
    case 'DINERS':
      return 14
    case 'AMEX':
      return 15
    default:
      return 16
  }
}

export const validateCreditCardNumber: Joi.CustomValidator<string> = (
  value,
  helper,
) => {
  const [formData] = helper.state.ancestors as CreditCardSchema[]

  const unmaskedValue = removeMask(value)
  if (
    unmaskedValue.length < getCreditCardNumberMaxLength(formData.cardBrandName)
  ) {
    return helper.error('string.min')
  }

  if (!luhn(unmaskedValue)) {
    return helper.error('string.invalid')
  }

  return value
}

export const validateCardholderName: Joi.CustomValidator<string> = (
  value,
  helper,
) => {
  const nameAndSurnameRegex = /^[a-zA-Z]{2}.*/

  if (!value.match(nameAndSurnameRegex)) {
    return helper.error('string.invalid')
  }

  return value
}

export const validateCardSecurityCode: Joi.CustomValidator<string> = (
  value,
  helper,
) => {
  if (value.length < MINIMUM_CVV_LENGTH) {
    return helper.error('string.min')
  }

  return value
}
