import {
  Button,
  Combobox,
  ComboboxItem,
  Input,
  Loader,
  Toast,
} from 'components'
import React, { useEffect } from 'react'

import { creditCardBrands } from '../../data/creditCardBrands'
import { BaseItem } from 'domains/account/types'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import {
  creditCardSchema,
  CreditCardSchema,
} from 'domains/account/paymentMethod/schemas/creditCard/creditCardSchema'
import { PaymentMethodRequest, PaymentMethodType } from 'services/account/types'

import styles from './CreditCardForm.module.scss'
import {
  creditCardNumberMasker,
  expiryDateMasker,
} from 'domains/account/paymentMethod/utilities/mask/mask'
import { getCreditCardNumberMaxLengthWithMask } from 'domains/account/paymentMethod/utilities/creditCard/getCreditCardNumberMaxLengthWithMask'
import { usePostPaymentMethod } from 'shared/hooks/services/account'
import { formatTimestampFromExpiryDate } from 'domains/account/paymentMethod/utilities/formatters/formatters'
import { useToastProps } from 'shared/hooks/components/toast'
import { usePutPaymentMethod } from 'shared/hooks/services/account/usePutPaymentMethod'
import { MutateOptions } from '@tanstack/react-query'
interface CreditCardFormProps {
  accountId: string
  paymentMethodType?: PaymentMethodType
  onSubmitSuccess: () => void
}

export function CreditCardForm({
  accountId,
  onSubmitSuccess,
  paymentMethodType,
}: CreditCardFormProps): JSX.Element {
  const comboboxCreditCardBrands: ComboboxItem<BaseItem>[] =
    creditCardBrands.map((brand) => ({
      label: 'name',
      value: brand,
    }))

  const {
    trigger,
    setValue,
    register,
    handleSubmit,
    watch,
    formState: { isDirty, isValid, errors },
  } = useForm<CreditCardSchema>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    resolver: joiResolver(creditCardSchema),
    defaultValues: {
      paymentMethodType: undefined,
      cardBrandName: '',
      accountId: '',
      cardNumber: '',
      cardSecurityCode: '',
      cardHolderName: '',
      cardExpiryDate: '',
    },
  })

  const cardNumber = watch('cardNumber')
  const cardBrandName = watch('cardBrandName')
  const cardExpiryDate = watch('cardExpiryDate')

  const saveMutation = usePostPaymentMethod()
  const editMutation = usePutPaymentMethod()

  const saveToastProps = useToastProps({
    status: saveMutation.status,
    successMessage: 'Forma de pagamento cadastrada com sucesso.',
    errorMessage:
      'Não foi possível cadastrar a forma de pagamento. Tente novamente.',
  })

  const editToastProps = useToastProps({
    status: editMutation.status,
    successMessage: 'Forma de pagamento editada com sucesso.',
    errorMessage:
      'Não foi possível editar a forma de pagamento. Tente novamente.',
  })

  const onSubmit = (form: CreditCardSchema) => {
    const request = {
      ...form,
      cardNumber: form.cardNumber.replace(/\D/g, ''),
      cardExpiryDate: formatTimestampFromExpiryDate(form.cardExpiryDate),
    }

    const options: MutateOptions<void, unknown, PaymentMethodRequest, unknown> =
      {
        onSuccess: () => {
          onSubmitSuccess()
        },
      }

    if (paymentMethodType) {
      editMutation.mutate(request, options)
    } else {
      saveMutation.mutate(request, options)
    }
  }

  useEffect(() => {
    register('cardBrandName')
  }, [register])

  useEffect(() => {
    setValue('paymentMethodType', 'CREDIT_CARD', {
      shouldDirty: true,
    })
    setValue('accountId', accountId, { shouldDirty: true })
  }, [setValue, accountId])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.container}>
      <Toast {...saveToastProps} />
      <Toast {...editToastProps} />
      <Loader isVisible={saveMutation.isLoading || editMutation.isLoading} />
      <Combobox
        label="Bandeira"
        items={comboboxCreditCardBrands}
        getSelected={(item) => {
          if (item?.value) {
            setValue('cardBrandName', item.value.id)
            trigger('cardBrandName')
          }
        }}
      />
      <Input
        label="Numeração do cartão"
        value={creditCardNumberMasker(cardNumber, cardBrandName)}
        maxLength={getCreditCardNumberMaxLengthWithMask(cardBrandName)}
        inputMode="numeric"
        {...register('cardNumber')}
        errorMessage={errors.cardNumber?.message}
      />
      <div className={styles.middleInputsWrapper}>
        <Input
          label="Data de validade"
          value={expiryDateMasker(cardExpiryDate)}
          maxLength={5}
          inputMode="numeric"
          {...register('cardExpiryDate')}
          errorMessage={errors.cardExpiryDate?.message}
        />
        <Input
          label="CVV"
          type="number"
          {...register('cardSecurityCode')}
          maxLength={4}
          inputMode="numeric"
          errorMessage={errors.cardSecurityCode?.message}
        />
      </div>
      <Input
        label="Nome do titular"
        {...register('cardHolderName')}
        errorMessage={errors.cardHolderName?.message}
      />
      <Button
        title="Salvar"
        htmlType="submit"
        disabled={!isDirty || !isValid}
      />
    </form>
  )
}
