import { ButtonV2 } from 'components'
import { FormItem, FormLabel } from 'components/Form'
import ComboBox from 'components/Form/ComboBox'
import Modal from 'components/ModalV2'
import { useForm } from 'react-hook-form'

import styles from './styles.module.scss'
import { findAllPatrimonies } from 'services/patrimony'
import { useCallback } from 'react'
import { capitalize } from 'utilities/capitalize'
import { queryAccounts } from 'services/account'
import { parseCookies } from 'nookies'
import { useQueryClient } from '@tanstack/react-query'

interface ChangeAccount {
  isVisible: boolean
  onClose: () => void
}

type Form = {
  patrimony: {
    id: string
    name: string
  }
  account?: {
    id: string
    name: string
  }
}

const ChangeAccount = ({ isVisible, onClose }: ChangeAccount) => {
  const { userId } = parseCookies() || ''

  const queryClient = useQueryClient()

  const { setValue, watch, handleSubmit } = useForm<Form>()

  const { patrimony, account } = watch()

  const handleGetPatrimonies = useCallback(async () => {
    const response = await findAllPatrimonies(userId)

    const fullAddress = response.map((patrimony) => ({
      ...patrimony,
      name: `${patrimony.address.address}, ${patrimony.address.number}, ${capitalize(patrimony.address.district)}, ${capitalize(patrimony.address.city)}/${patrimony.address.acronym}`,
    }))

    return { data: fullAddress, totalElements: fullAddress.length }
  }, [userId])

  const handleGetAccounts = useCallback(
    async (code: string) => {
      if (patrimony?.id) {
        const response = await queryAccounts(patrimony.id, code)

        const accounts = response.data.map((account) => ({
          ...account,
          name: `${account.code} [${account.serviceType}] ${account.name}`,
        }))

        return { data: accounts, totalElements: response.totalElements }
      }

      return { data: [], totalElements: 0 }
    },
    [patrimony],
  )

  const handleOnSubmit = () => {
    localStorage.setItem('accountId', account?.id || '')

    queryClient.invalidateQueries({ queryKey: ['account'] })

    onClose()
  }

  return (
    <Modal.Root isOpen={isVisible} onClose={onClose}>
      <Modal.Content className={styles.modalContent}>
        <Modal.Title>Selecionar patrimônio</Modal.Title>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className={styles.content}>
            <p>
              Selecione um patrimônio para visualizar seus eventos, plano de
              ação, usuários e consultar equipamentos.
            </p>

            <FormItem>
              <FormLabel>Patrimônio</FormLabel>
              <ComboBox.Root valueKey="name" findOptions={handleGetPatrimonies}>
                {({ unSelectedOptions }) => (
                  <>
                    <ComboBox.Field value={patrimony?.name} />
                    <ComboBox.Group>
                      {unSelectedOptions?.map((option) => (
                        <ComboBox.Option
                          key={option.id}
                          onClick={() => {
                            setValue(
                              'patrimony',
                              {
                                id: option.id,
                                name: option.name,
                              },
                              {
                                shouldValidate: true,
                              },
                            )

                            setValue('account', undefined, {
                              shouldValidate: true,
                            })
                          }}
                        >
                          {option.name}
                        </ComboBox.Option>
                      ))}
                    </ComboBox.Group>
                  </>
                )}
              </ComboBox.Root>
            </FormItem>

            <FormItem>
              <FormLabel>Conta</FormLabel>
              <ComboBox.Root valueKey="name" findOptions={handleGetAccounts}>
                {({ unSelectedOptions }) => (
                  <>
                    <ComboBox.Field value={account?.name} />
                    <ComboBox.Group>
                      {unSelectedOptions?.map((option) => (
                        <ComboBox.Option
                          key={option.id}
                          onClick={() => {
                            setValue(
                              'account',
                              {
                                id: option.id,
                                name: option.name,
                              },
                              {
                                shouldValidate: true,
                              },
                            )
                          }}
                        >
                          {option.name}
                        </ComboBox.Option>
                      ))}
                    </ComboBox.Group>
                  </>
                )}
              </ComboBox.Root>
            </FormItem>
          </div>

          <Modal.Footer>
            <Modal.Close asChild>
              <ButtonV2 appearance="tertiary">Cancelar</ButtonV2>
            </Modal.Close>
            <ButtonV2 type="submit">Continuar</ButtonV2>
          </Modal.Footer>
        </form>
      </Modal.Content>
    </Modal.Root>
  )
}

export default ChangeAccount
