import { useMutation } from '@tanstack/react-query'
import { AxiosInstance } from 'axios'
import { httpClient } from 'config/axios'
import { endpoints } from 'services/auth/endpoints'
import {
  LoginRequest,
  ResetPasswordRequest,
  TokenRequest,
  TokenResponse,
  UserLoggedIn,
} from 'services/auth/types'

interface AuthServiceDriver {
  login: (payload: TokenRequest) => Promise<TokenResponse>
}

class AuthServiceDriverImpl implements AuthServiceDriver {
  public constructor(private readonly client: AxiosInstance = httpClient) {}

  public async login(payload: TokenRequest): Promise<TokenResponse> {
    const response = await this.client.post<TokenResponse>('/token', payload)

    return response.data
  }
}

export const AuthService = new AuthServiceDriverImpl()

const logon = async (payload: LoginRequest): Promise<UserLoggedIn> => {
  const response = await httpClient.post(endpoints.login, payload)

  return response.data as UserLoggedIn
}

export const usePostLogon = () =>
  useMutation({
    mutationFn: async (payload: LoginRequest) => {
      return await logon({
        ...payload,
        mobileDeviceToken: 'fake_token',
        mobileDeviceType: 'ANDROID',
      })
    },
  })

const resetPassword = async ({
  email,
}: ResetPasswordRequest): Promise<void> => {
  await httpClient.post(endpoints.resetPassword, { email })
}

export const usePostResetPassword = () =>
  useMutation({
    mutationFn: async ({ email }: ResetPasswordRequest) => {
      return await resetPassword({ email })
    },
  })
