import styles from './Button.module.scss'

type HtmlButtonType = 'submit' | 'reset' | 'button'

interface ButtonProps {
  title: string
  onClick?: () => void
  htmlType?: HtmlButtonType
  disabled?: boolean
}
export function Button({
  title,
  onClick,
  htmlType,
  disabled,
}: ButtonProps): JSX.Element {
  return (
    <button
      className={styles.container}
      type={htmlType}
      disabled={disabled}
      onClick={onClick}
      aria-label={title}
    >
      <div className={styles.title}>{title}</div>
    </button>
  )
}
