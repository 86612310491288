import { ToastProps } from 'components'
import { useMemo } from 'react'

type Status = 'error' | 'idle' | 'loading' | 'success'

type ToastHookProps = {
  status: Status
  successMessage: string
  errorMessage: string
}

export function useToastProps({
  status,
  successMessage,
  errorMessage,
}: ToastHookProps) {
  const props = useMemo(() => {
    let props: ToastProps = {
      isVisible: false,
      message: '',
    }

    if (status === 'success') {
      props = {
        isVisible: true,
        message: successMessage,
      }
    }

    if (status === 'error') {
      props = {
        isVisible: true,
        message: errorMessage,
        error: true,
      }
    }

    return props
  }, [status, successMessage, errorMessage])

  return props
}
