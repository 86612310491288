export function generateMonthlyFilters(): {
  name: string
  createdFrom: number
  createdTo: number
}[] {
  const startDate = new Date(2023, 2)
  const currentDate = new Date()

  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ]

  const filters: { name: string; createdFrom: number; createdTo: number }[] = []

  const iterDate = new Date(startDate)
  while (
    iterDate.getFullYear() < currentDate.getFullYear() ||
    (iterDate.getFullYear() === currentDate.getFullYear() &&
      iterDate.getMonth() <= currentDate.getMonth())
  ) {
    const month = iterDate.getMonth()
    const year = iterDate.getFullYear()
    const name = `${monthNames[month]}/${year}`

    const createdFrom = new Date(year, month, 1).getTime()
    const createdTo = new Date(year, month + 1, 0, 23, 59, 59, 999).getTime()

    filters.push({ name, createdFrom, createdTo })

    iterDate.setMonth(iterDate.getMonth() + 1)
  }

  return filters.sort((a, b) => b.createdFrom - a.createdFrom)
}
