import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'

import './assets/styles/reset.scss'
import 'assets/theme/index.scss'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root')!)

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
)
