import { ComponentProps, forwardRef } from 'react'
import styles from '../field.module.scss'

const Input = forwardRef<HTMLInputElement, ComponentProps<'input'>>(
  ({ className, ...props }, ref) => (
    <input
      ref={ref}
      placeholder=" "
      {...props}
      className={[styles.field, className].join(' ')}
    />
  ),
)

Input.displayName = 'Input'

export default Input
