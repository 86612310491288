import { banks } from 'domains/account/paymentMethod/data/banks'
import { creditCardBrands } from 'domains/account/paymentMethod/data/creditCardBrands'
import React, { useMemo } from 'react'

import defaultBankIcon from '../../assets/images/banks/bank.png'
import defaultCreditCardIcon from '../../assets/images/creditCardBrands/creditCard.png'

import { PaymentMethod } from 'services/account/types'

import styles from './RegisteredPayment.module.scss'

interface PaymentMethodInfo {
  paymentMethodType: 'Cartão de crédito' | 'Débito em conta' | 'Boleto bancário'
  paymentMethodData: string
  image?: string
}

interface RegisteredPaymentProps {
  registeredPaymentMethod: PaymentMethod
  onEdit(): void
}

export const RegisteredPayment = ({
  registeredPaymentMethod,
  onEdit,
}: RegisteredPaymentProps) => {
  const {
    cardBrandName,
    paymentMethodType,
    cardNumber,
    agencyNumber,
    accountNumber,
    bankNumber,
  } = registeredPaymentMethod

  const paymentInfo = useMemo(() => {
    let infos: PaymentMethodInfo

    if (paymentMethodType === 'CREDIT_CARD') {
      const creditCardBrand = creditCardBrands.find(
        (brand) => brand.id.toLowerCase() === cardBrandName.toLowerCase(),
      )
      infos = {
        paymentMethodType: 'Cartão de crédito',
        paymentMethodData: `•••• ${cardNumber}`,
        image: creditCardBrand?.imgPath || defaultCreditCardIcon,
      }
    } else if (paymentMethodType === 'DIRECT_DEBIT') {
      const bank = banks.find(
        (bank) => bank.id === String(bankNumber).padStart(3, '0'),
      )
      infos = {
        paymentMethodType: 'Débito em conta',
        paymentMethodData: `Ag. ••${agencyNumber} Conta ••${accountNumber}`,
        image: bank?.imgPath || defaultBankIcon,
      }
    } else {
      infos = {
        paymentMethodType: 'Boleto bancário',
        paymentMethodData: '',
        image: undefined,
      }
    }

    return infos
  }, [
    paymentMethodType,
    cardNumber,
    cardBrandName,
    agencyNumber,
    accountNumber,
    bankNumber,
  ])

  if (!paymentInfo) return <></>

  return (
    <div className={styles.container}>
      {paymentInfo.image && (
        <div className={styles.iconWrapper}>
          <img src={paymentInfo.image} alt="bank-icon" />
        </div>
      )}
      <div>
        <p className={styles.info}>{paymentInfo.paymentMethodType}</p>
        <p className={[styles.info, styles.subInfo].join(' ')}>
          {paymentInfo.paymentMethodData}
        </p>
      </div>

      <div className={styles.spacing} />

      <div className={styles.edit} onClick={onEdit}>
        <p>Substituir</p>
      </div>
    </div>
  )
}
